import t from 'core/helpers/t';
import DolphinGif from 'app/assets/dolphin.gif';
import DolphinSlowGif from 'app/assets/dolphin-slow.gif';

interface SpinnerProps {
  isFast?: boolean;
  /**
   * @default false
   */
  isFullScreen?: boolean;
}

export default function Spinner({ isFast = false, isFullScreen = false }: SpinnerProps) {
  return isFullScreen ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center opacity-60 backdrop-blur-sm">
      <img src={isFast ? DolphinGif : DolphinSlowGif} className="size-24 animate-pulse" alt={t('Načítavam...')} />
    </div>
  ) : (
    <div className="relative flex items-center justify-center">
      <div className="absolute inset-0 z-50 opacity-60 backdrop-blur-sm"></div>
      <img src={isFast ? DolphinGif : DolphinSlowGif} className="z-50 size-16 animate-pulse" alt={t('Načítavam...')} />
    </div>
  );
}
