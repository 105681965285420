import t from 'core/helpers/t';
import Spinner from 'core/components/Spinner';

export default function Fallback() {
  return (
    <div>
      <h1 className="m-4 flex items-center justify-center font-bold text-red-500">{t('Vyskytla sa chyba!')}</h1>
      <Spinner isFullScreen />
    </div>
  );
}
