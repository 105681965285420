import { useState } from 'react';
import { LiaInfoCircleSolid } from 'react-icons/lia';
import CompanyFormModal from 'company/components/CompanyFormModal';

import t from 'core/helpers/t';
import Button from 'core/components/Button';
import useEnumList from 'core/api/useEnumList';
import { CompanyModel } from 'app/api/requests';
import formatDate from 'core/helpers/formatDate';
import Spinner from 'core/components/Spinner.tsx';
import UserNameRenderer from 'core/containers/Table/colRenderers/UserNameRenderer';
import CompanyStatusRenderer from 'core/containers/Table/colRenderers/CompanyStatusRenderer';

interface Props {
  company: CompanyModel;
}

export default function CompanyInfo({ company }: Props) {
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const { data: countries, isLoading: isCountryEnumLoading } = useEnumList('countries');

  const searchCountryName = (value?: number | string): string | undefined => {
    const country = countries.find((country) => country.value == String(value));
    return country?.label;
  };

  const { data: banks, isLoading: isBankEnumLoading } = useEnumList('banks');

  const searchBankName = (value?: null | number | string): string | undefined => {
    const bank = banks.find((bank) => bank.value == String(value));
    return bank?.label?.name;
  };

  return (
    <>
      <div className="card m-6 space-y-4 rounded-md p-4">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center space-x-2">
            <LiaInfoCircleSolid className="h-auto w-8" />
            <h2>{t('Základné údaje spoločnosti')}</h2>
          </div>
          <Button
            onClick={() => {
              setEditModalOpen(true);
            }}
            label="Upraviť údaje"
            variant="raised"
          />
        </div>

        <div className="grid items-start gap-4 p-4 md:grid-cols-2">
          <div>
            <h3 className="font-semibold text-primary-500">{t('Identifikačné údaje')}</h3>
            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Názov spoločnosti')}
              </span>
              <span className="my-1 font-semibold">{company.company_name}</span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">{t('IČO')}</span>
              <span className="my-1 font-semibold">{company.ico}</span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">{t('DIČ')}</span>
              <span className="my-1 font-semibold">{company.dic}</span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('IČ DPH')}
              </span>
              <span className="my-1 font-semibold">{company.ic_dph}</span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Platca DPH')}
              </span>
              <span className="my-1 font-semibold">{company.is_vat_payer ? 'Áno' : 'Nie'}</span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Zapísaná v registri')}
              </span>
              <span className="my-1 font-semibold">{company.registered_in}</span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Činnosť (SK NACE)')}
              </span>
              <span className="my-1 font-semibold">{company.sk_nace}</span>
            </div>
          </div>

          <div>
            <h3 className="font-semibold text-primary-500">{t('Obchodné informácie')}</h3>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Aktuálny stav')}
              </span>
              <span className="my-1 font-semibold">
                <CompanyStatusRenderer status={company.current_status} hasTextOnly />
              </span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Pridelený obchodník')}
              </span>
              <span className="my-1 font-semibold">
                {company.assigned_salesperson_id ? <UserNameRenderer userId={company.assigned_salesperson_id} /> : null}
              </span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Pridelený partner/tipér')}
              </span>
              <span className="my-1 font-semibold">{company.assigned_partner}</span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Koordinátor')}
              </span>
              <span className="my-1 font-semibold">
                {company.coordinator_id ? <UserNameRenderer userId={company.coordinator_id} /> : null}
              </span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Vytvoril')}
              </span>
              <span className="my-1 font-semibold">
                {company.created_by ? <UserNameRenderer userId={company.created_by} /> : null}
              </span>
            </div>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Dátum zmluvy o posk. služieb')}
              </span>
              <span className="my-1 font-semibold">{formatDate(company.service_contract_date)}</span>
            </div>
          </div>

          {company.address && (
            <div>
              <h3 className="font-semibold text-primary-500">{t('Sídlo spoločnosti / fakturačná adresa')}</h3>
              <div>
                <div className="flex">
                  <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                    {t('Ulica')}
                  </span>
                  <span className="my-1 font-semibold">{company.address.street_name}</span>
                </div>
                <div className="flex">
                  <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                    {t('Číslo')}
                  </span>
                  <span className="my-1 font-semibold">{company.address.number}</span>
                </div>
                <div className="flex">
                  <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                    {t('Obec')}
                  </span>
                  <span className="my-1 font-semibold">{company.address.town}</span>
                </div>
                <div className="flex">
                  <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                    {t('PSČ')}
                  </span>
                  <span className="my-1 font-semibold">{company.address.psc}</span>
                </div>
                <div className="flex">
                  <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                    {t('Štát')}
                  </span>
                  {isCountryEnumLoading ? (
                    <Spinner />
                  ) : (
                    <span className="my-1 font-semibold">{searchCountryName(company.address.country_id)}</span>
                  )}
                </div>
              </div>
            </div>
          )}

          {company.mail_address && (
            <div>
              <h3 className="font-semibold text-primary-500">{t('Korešpondenčná adresa')}</h3>
              <>
                <div className="flex">
                  <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                    {t('Ulica')}
                  </span>
                  <span className="my-1 font-semibold">{company.mail_address.street_name}</span>
                </div>

                <div className="flex">
                  <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                    {t('Číslo')}
                  </span>
                  <span className="my-1 font-semibold">{company.mail_address.number}</span>
                </div>

                <div className="flex">
                  <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                    {t('Obec')}
                  </span>
                  <span className="my-1 font-semibold">{company.mail_address.town}</span>
                </div>

                <div className="flex">
                  <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                    {t('PSČ')}
                  </span>
                  <span className="my-1 font-semibold">{company.mail_address.psc}</span>
                </div>

                <div className="flex">
                  <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                    {t('Štát')}
                  </span>
                  {isCountryEnumLoading ? (
                    <Spinner />
                  ) : (
                    <span className="my-1 font-semibold">{searchCountryName(company.address?.country_id)}</span>
                  )}
                </div>
              </>
            </div>
          )}

          <div>
            <h3 className="font-semibold text-primary-500">{t('Bankové údaje')}</h3>

            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('IBAN')}
              </span>
              <span className="my-1 font-semibold">{company.iban}</span>
            </div>
            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('Banka')}
              </span>
              {isBankEnumLoading ? (
                <Spinner />
              ) : (
                <span className="my-1 font-semibold">{searchBankName(company.bank)}</span>
              )}
            </div>
            <div className="flex">
              <span className="my-1 mb-2 text-neutral-500 sm:mb-0 sm:min-w-32 md:min-w-48 lg:min-w-64">
                {t('SWIFT/BIC')}
              </span>
              <span className="my-1 font-semibold">{company.swift_bic}</span>
            </div>
          </div>
        </div>
      </div>

      <CompanyFormModal
        onClose={() => {
          setEditModalOpen(false);
        }}
        isOpen={isEditModalOpen}
        company={company}
      />
    </>
  );
}
