import { lazy, useMemo } from 'react';

import t from 'core/helpers/t';

import { useCompanyTabs } from './pages/CompanyDetail/tabs/useCompanyTabs';

// eslint-disable-next-line react-refresh/only-export-components
const CompanyDetailPage = lazy(() => import('./pages/CompanyDetail'));
// eslint-disable-next-line react-refresh/only-export-components
const CompanyListPage = lazy(() => import('./pages/CompanyList'));

export function useCompanyRoutes() {
  const companyTabs = useCompanyTabs();

  return useMemo(
    () => [
      { element: <CompanyListPage />, label: t('Zoznam spoločností'), path: 'companies' },
      {
        element: <CompanyDetailPage />,
        label: t('Detail spoločnosti'),
        path: 'companies/:companyId/*',
        prevBreadcrumb: 'companies',
        routes: companyTabs,
      },
    ],
    [companyTabs]
  );
}
