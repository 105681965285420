import * as yup from 'yup';
import { useMemo } from 'react';

import t from 'core/helpers/t';
import { CompanyDocumentCategory } from 'app/api/requests';
import { validateDocument } from 'core/helpers/useValidation';

export type FormData = yup.InferType<ReturnType<typeof useSchema>>;

export default function useSchema() {
  return useMemo(
    () =>
      yup.object().shape({
        category_code: yup
          .mixed<CompanyDocumentCategory>()
          .oneOf(['ee', 'zp', 'contract', 'other'])
          .label(t('Kategória dokumentu'))
          .nullable(),
        file: validateDocument(),
        important: yup.boolean().label(t('Dôležitý dokument')),
        note: yup.string().max(512).label(t('Poznámka k dokumentu')).nullable().default(''),
        title: yup.string().label(t('Názov dokumentu')).required(),
      }),
    []
  );
}
