import clsx from 'clsx';
import { useRef, useState, ReactNode } from 'react';
import { LiaSyncSolid, LiaFileAltSolid } from 'react-icons/lia';

import t from 'core/helpers/t';
import Label from 'core/components/Label';
import Tooltip from 'core/components/Tooltip';

interface Props {
  name: string;
  isBold?: boolean;
  label?: ReactNode;
  value: File | null;
  isChanged: boolean;
  errorText?: string;
  isRequired: boolean;
  isDisabled: boolean;
  isTouched?: boolean;
  truncateWidth?: string;
  labelClassName?: string;
  hasInlineLabel?: boolean;
  acceptedFileTypes?: string;
  setValue: (file: File | null) => void;
}

export default function Upload({
  acceptedFileTypes = '*',
  errorText,
  hasInlineLabel = false,
  isBold = false,
  isDisabled = false,
  isRequired = false,
  label,
  labelClassName,
  name,
  setValue,
  truncateWidth = 'max-w-md',
  value,
}: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isDragOver, setIsDragOver] = useState(!value);

  // Drag & drop handlers
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);

    const files = e.dataTransfer.files;
    const file = files[0];
    setValue(file);
  };

  return (
    <div className="flex flex-col gap-2">
      <div
        className={clsx('flex justify-start gap-2', {
          'flex-col': !hasInlineLabel,
          'flex-row items-center': hasInlineLabel,
        })}
      >
        <Label
          hasInlineLabel={hasInlineLabel}
          className={labelClassName}
          isRequired={isRequired}
          name={`label-${name}`}
          isBold={isBold}
          label={label}
        />

        <div className="relative w-full" id="upload-area">
          <div
            className={clsx(
              'flex w-full min-w-0 grow rounded-md border text-neutral-700 focus:outline-none focus:ring-2 focus:ring-primary-500',
              {
                'bg-neutral-200 opacity-50 cursor-not-allowed': isDisabled,
                'border-red-500': errorText,
                'cursor-default': !!value && !isDisabled,
                'cursor-pointer': isDragOver && !value,
              }
            )}
            onClick={() => !isDisabled && !value && inputRef.current?.click()}
            onKeyDown={() => {
              //
            }}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            role="button"
            tabIndex={0}
          >
            <div className="rounded-l-md bg-neutral-200 px-3 py-2">
              <LiaFileAltSolid size={30} />
            </div>
            <div className="flex items-center px-3 py-2">
              <p className="text-center text-neutral-600">
                {value?.name ? (
                  <span>
                    <span className={clsx('inline-block truncate align-middle', truncateWidth)}>{t(value.name)}</span>{' '}
                    <span>({(value.size / 1024).toFixed(2)} kB)</span>
                  </span>
                ) : (
                  t('Presuňte súbor sem alebo kliknite pre nahratie')
                )}
              </p>
            </div>

            {value ? (
              <Tooltip content={t('Nahradiť súbor')}>
                <div className="z-50 ml-auto flex cursor-pointer gap-2 px-3 py-2">
                  <LiaSyncSolid onClick={() => !isDisabled && inputRef.current?.click()} size={30} />
                </div>
              </Tooltip>
            ) : null}

            <input
              onChange={(e) => {
                const file = e.currentTarget.files?.[0];
                setValue(file ?? null);
              }}
              accept={acceptedFileTypes}
              disabled={isDisabled}
              ref={inputRef}
              name={name}
              type="file"
              id={name}
              hidden
            />
          </div>
          {errorText && <div className="text-error mt-1">{errorText}</div>}
        </div>
      </div>
    </div>
  );
}
