import type { Props as ButtonProps } from 'core/components/Button';

import { FC } from 'react';
import { useFormSelect } from '@serusko/reform';

import Button from 'core/components/Button';

const SubmitButton: FC<ButtonProps> = ({ isDisabled, ...props }) => {
  const formDisabled = useFormSelect((s) => !!s.disabled || !!s.isSubmitting);

  return <Button {...props} isDisabled={!!isDisabled || formDisabled} />;
};

export default SubmitButton;
