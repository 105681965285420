import { ReactNode, ComponentType } from 'react';
export type ColDef<D> = CustomColDef<D> | FieldColDef<D>;

export interface CustomColDef<D> {
  key: string;
  header: string;
  headerClassName?: string;
  render: (props: {
    /** Row item data */
    data: D;
    /** Number of displayed column */
    colNum: number;
    /** Number of displayed line/row */
    rowNum: number;
  }) => ReactNode;
}

export interface FieldColDef<D> {
  field: keyof D;
  header: string;
  route?: string;
  headerClassName?: string;
}

export type RowAction<D> = RowIconAction<D> | RowRenderAction<D>;

interface Props<D> {
  data: D;
  rowNum: number;
  columns: ColDef<D>[];
  onRowClick?: () => void;
  rowActions?: RowAction<D>[];
}

/** Standard Icon button row action */
interface RowIconAction<D> {
  key: string;
  icon: ReactNode;
  label?: ReactNode;
  onClick: (item: D) => void;
}

/** Custom component row action */
interface RowRenderAction<D> {
  key: string;
  render: ComponentType<{ data: D; rowNum: number; colNum: number }>;
}

function TableItem<D>({ columns, data, onRowClick, rowActions = [], rowNum }: Props<D>) {
  return (
    <tr className="bg-white odd:bg-neutral-100" onClick={onRowClick}>
      {columns.map((c, colNum) => (
        <td key={'field' in c ? String(c.field) : c.key} className="px-4 py-[0.6rem]">
          {'field' in c && data[c.field] && String(data[c.field])}
          {'render' in c && <c.render {...{ colNum, data, rowNum }} />}
        </td>
      ))}

      {rowActions.length > 0 && (
        <td>
          <div className="flex items-center justify-center space-x-2 px-2">
            {rowActions.map((action, index) =>
              'icon' in action ? (
                <button
                  onClick={() => {
                    action.onClick(data);
                  }}
                  className="flex items-center"
                  key={index}
                >
                  {action.icon}
                  {action.label && <span className="ml-1">{action.label}</span>}
                </button>
              ) : (
                <action.render colNum={columns.length} key={action.key} rowNum={rowNum} data={data} />
              )
            )}
          </div>
        </td>
      )}
    </tr>
  );
}

export default TableItem;
