import clsx from 'clsx';
import { useRef, ReactNode, useEffect, ReactElement } from 'react';

import Label from 'core/components/Label';
import useSchemaMaxLen from 'core/containers/Form/useSchemaMaxLen';

export interface Props {
  name: string;
  step?: number;
  isBold?: boolean;
  label?: ReactNode;
  errorText?: string;
  maxLength?: number;
  isChanged?: boolean;
  isTouched?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  value: null | string;
  labelClassName?: string;
  inputClassName?: string;
  hasInlineLabel?: boolean;
  isAutoComplete?: boolean;
  appendItem?: ReactElement;
  prependItem?: ReactElement;
  setValue: (v: null | string) => void;
  type?: 'date' | 'email' | 'number' | 'password' | 'phone' | 'text';
}

export default function Input({
  appendItem,
  errorText,
  hasInlineLabel = false,
  inputClassName,
  isAutoComplete = true,
  isBold = false,
  isChanged,
  isDisabled = false,
  isRequired = false,
  label,
  labelClassName,
  maxLength,
  name,
  prependItem,
  setValue,
  step,
  type = 'text',
  value,
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const schemaMaxLen = useSchemaMaxLen(name);
  const maxLen = typeof maxLength === 'number' ? maxLength : schemaMaxLen;

  // Handle date formatting if type is 'date'
  const formatDate = (date: null | string): string => {
    if (!date) return ''; // Handle null input gracefully

    // Split the input date to separate the date and time components
    const [datePart] = date.split(' ');

    return datePart; // Return only the date part (YYYY-MM-DD)
  };

  const formattedValue = type === 'date' ? formatDate(value) : (value ?? undefined);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;

    if (type === 'text') {
      const canUpdateValue = !maxLen || (value ?? '').length < maxLen || inputValue.length < (value ?? '').length;
      if (canUpdateValue) setValue(inputValue || null);
    } else if (type === 'date') {
      const isValidDate = !isNaN(new Date(inputValue).getTime()) || inputValue === '';
      if (isValidDate) setValue(inputValue || null);
    } else {
      setValue(inputValue || null);
    }
  };

  // Function to generate the date placeholder based on format
  const generateDatePlaceholder = () => {
    if (type === 'date') {
      return '__ . __ . _____'; // TODO not working
    }
    return '';
  };

  // Prevent scroll on number inputs
  useEffect(() => {
    const preventScroll = (e: WheelEvent) => {
      if (inputRef.current === document.activeElement && type === 'number') {
        e.preventDefault();
      }
    };

    window.addEventListener('wheel', preventScroll, { passive: false });
    return () => {
      window.removeEventListener('wheel', preventScroll);
    };
  }, [type]);

  return (
    <div className="flex w-full flex-col gap-2">
      <div
        className={clsx('flex w-full justify-start gap-2', {
          'flex-col': !hasInlineLabel,
          'flex-row': hasInlineLabel,
        })}
      >
        {label && (
          <Label
            className={clsx({ 'mt-2': hasInlineLabel }, labelClassName)}
            hasInlineLabel={hasInlineLabel}
            isRequired={isRequired}
            isBold={isBold}
            label={label}
            name={name}
          />
        )}

        <div className="w-full grow">
          <div className={clsx('relative', { 'opacity-50 cursor-not-allowed': isDisabled })}>
            {prependItem}
            <input
              className={clsx(
                'w-full rounded-md border bg-neutral-200 px-3 py-2 text-neutral-700 focus:outline-none focus:ring-2 focus:ring-primary-500',
                {
                  '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-right':
                    type === 'number',
                  'bg-neutral-200 cursor-not-allowed': isDisabled,
                  'border-red-500': errorText,
                  'text-neutral-200': type === 'date' && !isChanged,
                },
                inputClassName
              )}
              onWheel={(e) => {
                if (type === 'number') e.currentTarget.blur();
              }}
              autoComplete={isAutoComplete ? 'on' : 'off'}
              placeholder={generateDatePlaceholder()}
              value={formattedValue || ''}
              onChange={handleChange}
              disabled={isDisabled}
              id={`input-${name}`}
              ref={inputRef}
              name={name}
              step={step}
              type={type}
            />
            {appendItem}
          </div>

          {(!!errorText || !!maxLen) && (
            <div className="mt-1 inline-block w-full">
              {errorText && <span className="text-error text-xs">{errorText}</span>}

              {maxLen && type !== 'number' && (
                <span className="float-right ml-auto text-xs text-neutral-500">
                  ({(value ?? '').length}/{maxLen})
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
