import clsx from 'clsx';
import { useRef, useState, useEffect } from 'react';
import { LiaTimesSolid, LiaTimesCircle, LiaAngleDownSolid } from 'react-icons/lia';

import t from 'core/helpers/t';
import Label from 'core/components/Label';
import { search } from 'core/helpers/search';

export interface Option<V extends OptionValue = OptionValue> {
  value: V;
  label: string;
  key?: number | string;
}

export type OptionValue = boolean | null | number | string | symbol;

export interface SelectProps<V extends OptionValue = OptionValue> {
  name: string;
  label?: string;
  isBold?: boolean;
  errorText?: string;
  isSearch?: boolean;
  hasInsert?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  options: Option<V>[];
  isMultiple?: boolean;
  value: null | V | V[];
  truncateWidth?: string;
  labelClassName?: string;
  inputClassName?: string;
  hasInlineLabel?: boolean;
  setValue: (value: null | string | V | V[]) => void;
}

export default function Select<V extends OptionValue>({
  errorText,
  hasInlineLabel = false,
  hasInsert = false,
  inputClassName,
  isBold = false,
  isDisabled = false,
  isMultiple = false,
  isRequired = false,
  isSearch = false,
  label,
  labelClassName,
  name,
  options,
  setValue,
  truncateWidth,
  value,
}: SelectProps<V>) {
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [dynamicOptions, setDynamicOptions] = useState<Option<V>[]>(options);
  const [filteredOptions, setFilteredOptions] = useState<Option<V>[]>(options);

  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef, isOpen]);

  const handleClear = () => {
    setSearchQuery(''); // Clear the input field
    if (searchRef.current) {
      searchRef.current.focus(); // Focus the input field
    }
  };

  // Map to store refs for each option
  const optionRefs = useRef(new Map());

  useEffect(() => {
    // If value is a single selection (not multiple)
    if (!Array.isArray(value)) {
      const targetRef = optionRefs.current.get(value);
      if (targetRef) {
        targetRef.scrollIntoView({
          behavior: 'instant',
          block: 'center',
        });
      }
    }
  }, [value, isOpen]);

  // Dynamically stored values before added to option list when loaded from database
  useEffect(() => {
    if (
      value != null &&
      typeof value === 'string' &&
      !dynamicOptions.some(
        (option) =>
          (typeof option.value === 'string' && option.value.toLowerCase() === value.toLowerCase()) ||
          option.label.toLowerCase() === value.toLowerCase()
      )
    ) {
      const newOption: Option<V> = {
        key: value,
        label: String(value),
        value: value as V,
      };
      setDynamicOptions((prevOptions) => [...prevOptions, newOption]);
    }
  }, [dynamicOptions, value]);

  useEffect(() => {
    const filtered =
      isSearch || hasInsert
        ? dynamicOptions.filter((option) => {
            if (!searchQuery) {
              return true;
            }

            const value = String(option.value ?? '');
            return search(option.label, searchQuery) || search(value, searchQuery);
          })
        : dynamicOptions;
    setFilteredOptions(filtered);
  }, [searchQuery, isSearch, dynamicOptions, hasInsert]);

  const handleSelect = (selectedValue: V) => {
    if (isMultiple) {
      if (Array.isArray(value)) {
        setValue([...value, selectedValue]); // Add selected value to array
      } else {
        setValue([selectedValue]); // Initialize array if value is null
      }
    } else {
      setValue(selectedValue); // For single selection
    }
    setIsOpen(false);
  };

  const handleDeselect = (deselectedValue: V) => {
    if (isMultiple && Array.isArray(value)) {
      setValue(value.filter((val) => val !== deselectedValue)); // Remove selected value from array
    }
  };

  const handleAddNewOption = () => {
    const newOption: Option<V> = {
      key: searchQuery,
      label: searchQuery,
      value: searchQuery as V,
    };
    setSearchQuery('');
    setDynamicOptions((prevOptions) => [...prevOptions, newOption]);
    handleSelect(newOption.value);
  };

  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const [dropdownAbove, setDropdownAbove] = useState(false);

  useEffect(() => {
    function updateDropdownPosition() {
      if (selectRef.current) {
        const rect = selectRef.current.getBoundingClientRect();
        const dropdownHeight = 200; // Approximate height of dropdown (adjust as needed)
        const windowHeight = window.innerHeight;

        // Check if there's enough space below; otherwise, open above
        if (windowHeight - rect.bottom < dropdownHeight && rect.top > dropdownHeight) {
          setDropdownAbove(true);
        } else {
          setDropdownAbove(false);
        }
      }
    }

    // Call on mount and on every scroll/resize
    updateDropdownPosition();
    window.addEventListener('resize', updateDropdownPosition);
    window.addEventListener('scroll', updateDropdownPosition, true);

    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
      window.removeEventListener('scroll', updateDropdownPosition, true);
    };
  }, [isOpen, selectRef]);

  return (
    <div className={hasInlineLabel ? 'flex space-x-2' : 'block'} ref={selectRef}>
      {label && (
        <Label
          className={clsx('mt-2', labelClassName)}
          hasInlineLabel={hasInlineLabel}
          isRequired={isRequired}
          isBold={isBold}
          label={label}
          name={name}
        />
      )}
      <div className="relative flex-1">
        <button
          className={clsx(
            'flex w-full items-center justify-between rounded-md border bg-neutral-200 px-3 py-2 text-neutral-700 focus:outline-none focus:ring-2 focus:ring-primary-500',
            isDisabled ? 'cursor-not-allowed bg-neutral-200 opacity-50' : 'cursor-pointer',
            {
              'border-red-500': errorText,
            },
            inputClassName
          )}
          onClick={() => {
            if (!isDisabled) {
              setIsOpen((prev) => !prev);
            }
          }}
          disabled={isDisabled}
          type="button"
        >
          <span
            title={
              isMultiple && Array.isArray(value) && value.length > 0
                ? `${value.length} vybrané`
                : value
                  ? dynamicOptions.find((option) => option.value === value)?.label
                  : t('Vyberte...')
            }
            className={clsx('block truncate text-left', truncateWidth)}
          >
            {isMultiple && Array.isArray(value) && value.length > 0
              ? `${value.length} vybrané`
              : value
                ? (dynamicOptions.find((option) => option.value === value)?.label ?? t('Vyberte...'))
                : t('Vyberte...')}
          </span>
          <LiaAngleDownSolid className="ml-2 shrink-0" />
        </button>

        {isMultiple && Array.isArray(value) && value.length > 0 && (
          <div className="mt-2 flex flex-wrap gap-2">
            {value.map((selectedValue, index) => {
              const option = dynamicOptions.find((opt) => opt.value === selectedValue);
              return (
                <div
                  className="flex items-center space-x-1 rounded-md bg-blue-500 px-2 py-0 text-sm text-white"
                  key={index}
                >
                  <small>{option?.label}</small>
                  <button onClick={() => handleDeselect(selectedValue)} type="button">
                    <LiaTimesSolid className="text-white" size={10} />
                  </button>
                </div>
              );
            })}
          </div>
        )}

        {isOpen && (
          <div
            className={clsx(
              'card absolute z-50 w-full overflow-hidden rounded-md border bg-white shadow-md',
              dropdownAbove ? 'bottom-full' : 'top-9'
            )}
          >
            {(isSearch || hasInsert) && (
              <div className="sticky top-0 z-10 bg-white">
                <div className="relative flex items-center space-x-2">
                  <input
                    placeholder={
                      isSearch && hasInsert
                        ? t('Vyhľadajte alebo vložte...')
                        : isSearch
                          ? t('Vyhľadajte...')
                          : t('Vložte...')
                    }
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    className="w-full border-b px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500"
                    disabled={isDisabled}
                    value={searchQuery}
                    ref={searchRef}
                    type="text"
                  />
                  {searchQuery && (
                    <button
                      className="absolute inset-y-0 right-0 flex items-center pr-3 text-select-clear"
                      disabled={isDisabled}
                      onClick={handleClear}
                      type="button"
                    >
                      <LiaTimesCircle size={20} />
                    </button>
                  )}
                </div>
              </div>
            )}

            <div className="relative max-h-52 overflow-y-auto overflow-x-hidden">
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option, index) => (
                  <button
                    onClick={() => {
                      if (!isDisabled) {
                        if (isMultiple) {
                          if (Array.isArray(value)) {
                            if (value.includes(option.value)) {
                              setValue(value.filter((val) => val !== option.value));
                            } else {
                              setValue([...value, option.value]);
                            }
                          } else {
                            setValue([option.value]);
                          }
                        } else {
                          setValue(option.value);
                        }
                        setIsOpen(false);
                      }
                    }}
                    className={clsx('flex w-full items-center justify-start px-3 py-2 text-left hover:bg-neutral-200', {
                      'bg-neutral-100': option.value === value,
                      'cursor-not-allowed opacity-50 bg-neutral-200': isDisabled,
                      'cursor-pointer': !isDisabled,
                    })}
                    ref={(el) => {
                      // Store reference to each button in the map
                      optionRefs.current.set(option.value, el);
                    }}
                    key={option.key ?? index}
                    type="button"
                  >
                    {option.label}
                  </button>
                ))
              ) : (
                <>
                  {isSearch ? <div className="px-3 py-2 text-neutral-500">{t('Hľadaný výraz sa nenašiel')}</div> : null}
                </>
              )}

              {filteredOptions.length === 0 && hasInsert && searchQuery && (
                <button
                  className="flex w-full items-center justify-start bg-green-200 px-3 py-2 hover:bg-green-300"
                  onClick={handleAddNewOption}
                >
                  {t('Pridať novú možnosť')} {`"${searchQuery}"`}
                </button>
              )}
            </div>
          </div>
        )}
        {errorText && <div className="text-error mt-1">{errorText}</div>}
      </div>
    </div>
  );
}
