import type { AnyObjectSchema, SchemaFieldDescription } from 'yup';

import * as y from 'yup';
import { useMemo } from 'react';
import { useFormSelect } from '@serusko/reform';

export default function useSchemaLabel(fieldName: string) {
  const schema = useFormSelect((s) => (s as { schema?: AnyObjectSchema }).schema);

  return useMemo((): string | undefined => {
    try {
      const schemaDescription = (schema || y.object({})).describe({ context: {}, parent: {}, value: {} });
      const fieldPathParts = fieldName.split('.');
      return findLabel(schemaDescription.fields, fieldPathParts);
    } catch {
      //
    }
    return undefined;
  }, [fieldName, schema]);
}

const findLabel = (fields: Record<string, SchemaFieldDescription>, pathParts: string[]): string | undefined => {
  if (pathParts.length === 0) return undefined;

  const [currentField, ...remainingParts] = pathParts;
  const field = fields[currentField];

  // Check if it's a nested object
  if ('fields' in field && remainingParts.length > 0) {
    return findLabel(field.fields as Record<string, SchemaFieldDescription>, remainingParts);
  }

  return 'label' in field ? field.label || undefined : undefined;
};
