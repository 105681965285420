import { set } from 'object-path';
import { useCallback } from 'react';
import FormAction from '@serusko/reform/dist/FormAction';

import { FormState } from 'core/containers/Form';
import { BankOption, TownOption } from 'app/types/props';

import { FormData } from './useSchema';

export default function useFormReducer(bankOptions: BankOption[], townOptions: TownOption[]) {
  return useCallback(
    (state: FormState<FormData>, action: FormAction<FormData>) => {
      if (action.type === 'setValue') {
        if (state.values.same_as_company_address && action.name.startsWith('address') && action.value) {
          set(state.values, action.name, action.value);
          state.values.mail_address = state.values.address;
        }

        if (action.name === 'same_as_company_address') {
          if (!action.value) {
            state.values.mail_address = {
              country_id: 8375,
              number: '',
              psc: '',
              street_name: '',
              town: '',
            };
          } else {
            state.values.mail_address = state.values.address;
          }
        }

        // Bank fields logic
        if (action.name === 'bank') {
          const matchingBank = bankOptions.find((bank) => bank.value === action.value);

          if (matchingBank) {
            state.values.swift_bic = matchingBank.swift_code;
          }
        }

        if (action.name === 'swift_bic') {
          const matchingBank = bankOptions.find((bank) => bank.swift_code === action.value);

          if (matchingBank) {
            state.values.bank = matchingBank.value;
          }
        }

        if (action.name === 'iban' && typeof action.value === 'string' && action.value.length >= 8) {
          // Extract the substring from action.value (indexes 4, 5, 6, 7) which represent the bank code
          const searchSubstring = action.value[4] === '0' ? action.value.substring(5, 8) : action.value.substring(4, 8);

          // Find the bank option where the value includes the substring
          const matchingBank = bankOptions.find((bank) => bank.value == searchSubstring);

          if (matchingBank) {
            state.values.bank = matchingBank.value;
            state.values.swift_bic = matchingBank.swift_code;
          }
        }

        // Town fields logic
        if (['address.psc', 'address.town', 'mail_address.psc', 'mail_address.town'].includes(action.name)) {
          const value = (action.value ?? '') as string;
          const matchingTown = townOptions.find(
            (town) =>
              town.value === value ||
              (value.length > 4 && town.zip_code?.replace(/\s+/g, '') === value.replace(/\s+/g, ''))
          );

          if (matchingTown) {
            if (action.name === 'address.town') {
              state.values.address.psc = matchingTown.zip_code || '';
            }

            if (action.name === 'address.psc') {
              state.values.address.town = matchingTown.label || '';
            }

            if (action.name === 'mail_address.town') {
              state.values.mail_address.psc = matchingTown.zip_code || '';
            }

            if (action.name === 'mail_address.psc') {
              state.values.mail_address.town = matchingTown.label || '';
            }
          }
        }
      }

      return state;
    },
    [bankOptions, townOptions]
  );
}
