import { UserModel, PersonModel, CompanyContactPersonModelRequest } from 'app/api/requests';

export default function ContactPersonRenderer({
  contactPerson,
  hasFullNameOnly = false,
}: {
  hasFullNameOnly?: boolean;
  contactPerson?: CompanyContactPersonModelRequest | null | PersonModel | UserModel;
}) {
  if (!contactPerson) {
    return null;
  }

  const { email, mobile, name, surname, title } = contactPerson;

  let fullName = `${name ?? ''} ${surname ?? ''}`.trim();
  if (title) {
    fullName += `, ${title}`;
  }

  const other = !hasFullNameOnly ? [mobile, email].filter(Boolean) : [];

  const contactDetails = [fullName, ...other].join(', ');

  return <span>{contactDetails}</span>;
}
