import { HTMLProps, forwardRef } from 'react';
import {
  useId,
  useMergeRefs,
  useDelayGroup,
  FloatingArrow,
  FloatingPortal,
  useTransitionStyles,
} from '@floating-ui/react';

import useTooltipState from './useTooltipState';
import cssStyles from './TooltipContent.module.css';

const instantDuration = 0;
const duration = 250;

export const TooltipContent = forwardRef<
  HTMLDivElement,
  { context: never; arrowRef: never } & HTMLProps<HTMLDivElement>
>(function TooltipContent({ arrowRef, children, context, ...props }, propRef) {
  const state = useTooltipState();
  const id = useId();

  const { currentId, isInstantPhase } = useDelayGroup(state.context, { id });
  const ref = useMergeRefs([state.refs.setFloating, propRef]);
  const { isMounted, styles } = useTransitionStyles(state.context, {
    duration: isInstantPhase
      ? {
          close: currentId === id ? duration : instantDuration,
          open: instantDuration,
        }
      : duration,
    initial: { opacity: 0 },
  });

  if (!isMounted) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        style={{
          ...state.floatingStyles,
          ...styles,
        }}
        className={cssStyles.content}
        ref={ref}
        {...state.getFloatingProps(props)}
      >
        <FloatingArrow className={cssStyles.arrow} context={context} ref={arrowRef} />
        {children}
      </div>
    </FloatingPortal>
  );
});
