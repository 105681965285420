import t from 'core/helpers/t';

export const category = {
  purchasing: t('Výkup'),
  selling: t('Predaj'),
};

export const document_category_code = {
  contract: t('Zmluva'),
  ee: t('EE'),
  other: t('Ostatné'),
  zp: t('ZP'),
};
