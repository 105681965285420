import { useMemo } from 'react';
import { AnyObjectSchema } from 'yup';
import { useFormSelect } from '@serusko/reform';

import { FormState } from './props';

export default function useSchemaMaxLen(fieldName: string) {
  const schema = useFormSelect(
    (s): AnyObjectSchema | undefined => (s as { schema?: AnyObjectSchema } & FormState).schema
  );

  return useMemo(() => {
    try {
      // @ts-ignore ignore 2 generic yup typing
      const tests = schema && schema.describe({ context: {}, parent: {}, value: {} }).fields[fieldName].tests;
      if (tests && Array.isArray(tests)) {
        const test = (tests as { name: string; params: Record<string, number> }[]).find((t) => t.name === 'max');

        if (test) {
          return Number(test.params.max) || undefined;
        }
      }
    } catch {
      //
    }
    return undefined;
  }, [fieldName, schema]);
}
