import { useRef, useMemo, useState } from 'react';
import {
  flip,
  shift,
  arrow,
  offset,
  useRole,
  useHover,
  useFocus,
  autoUpdate,
  useDismiss,
  useFloating,
  safePolygon,
  useDelayGroup,
  useInteractions,
} from '@floating-ui/react';

import TooltipOptions from './props';

export function useTooltip({
  initialOpen = false,
  onOpenChange: setControlledOpen,
  open: controlledOpen,
  placement = 'bottom',
}: TooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const setOpen = setControlledOpen ?? setUncontrolledOpen;
  const open = controlledOpen ?? uncontrolledOpen;
  const arrowRef = useRef(null);

  const { context, floatingStyles, refs, ...rest } = useFloating({
    middleware: [offset(8), flip(), shift(), arrow({ element: arrowRef })],
    onOpenChange: setOpen,
    open,
    placement,
    whileElementsMounted: autoUpdate,
  });

  const focus = useFocus(context, { enabled: controlledOpen == null });
  const role = useRole(context, { role: 'tooltip' });
  const { delay } = useDelayGroup(context);
  const dismiss = useDismiss(context);

  const hover = useHover(context, {
    delay,
    enabled: controlledOpen == null,
    handleClose: safePolygon(),
    move: false,
  });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      arrowRef,
      open,
      setOpen,
      ...interactions,
      context,
      floatingStyles,
      refs,
      ...rest,
    }),
    [open, setOpen, interactions, context, floatingStyles, refs, rest]
  );
}
