import { useQuery } from '@tanstack/react-query';

import { EnumModel, BankEnumModel, TownEnumModel, SkNaceEnumModel } from 'app/api/requests';

type M = {
  towns: TownEnumModel;
  countries: EnumModel;
  banks: BankEnumModel;
  sk_nace: SkNaceEnumModel;
  company_business_status: EnumModel;
};

export default function useEnumList<K extends keyof M>(key: K) {
  const { data, isLoading } = useQuery<{ data?: Record<K, M[K]> }>({
    queryKey: ['system', `enums?type=${key}`],
    refetchInterval: 20 * 60 * 1000,
    refetchIntervalInBackground: true,
    staleTime: Infinity,
  });

  return { data: data?.data?.[key] || ([] as M[K]), isLoading };
}
