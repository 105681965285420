import { useState } from 'react';
import { LiaUser, LiaEditSolid, LiaTrashSolid, LiaUserTieSolid, LiaUserCheckSolid } from 'react-icons/lia';

import t from 'core/helpers/t';
import useList from 'core/api/useList';
import useSave from 'core/api/useSave';
import Button from 'core/components/Button';
import Tooltip from 'core/components/Tooltip';
import DeleteModal from 'core/components/DeleteModal';
import ApiTable from 'core/containers/Table/ApiTable';
import { GeneralComponentProps } from 'app/types/props';
import { CompanyContactPersonModel } from 'app/api/requests';

import useColumns from './useColumns';
import ContactPersonFormModal from './ContactPersonFormModal';

interface HeaderCardProps {
  data: GeneralComponentProps;
}

export default function ContactPersons({ data }: HeaderCardProps) {
  const columns = useColumns();

  const [modalState, setModalState] = useState<{
    mode: 'create' | 'edit' | null;
    contactPerson?: CompanyContactPersonModel;
  }>({ mode: null });

  const [deleteItem, setDeleteItem] = useState<CompanyContactPersonModel | undefined>(undefined);

  const { data: contactPersons } = useList<CompanyContactPersonModel>(data.url, false);

  const { mutateAsync: reorderMutation } = useSave(`PUT/${data.url}/order`, {
    onSuccess: t('Primárna kontaktná osoba úspešne zmenená!'),
  });

  const handleSetFavorite = (contactPerson: CompanyContactPersonModel) => {
    if (!contactPersons) {
      return;
    }
    const id = Number(contactPerson.id);

    const newOrder: number[] = [id, ...contactPersons.filter((person) => person.id !== id).map(({ id }) => Number(id))];

    void reorderMutation(newOrder);
  };

  const rowActions = [
    {
      key: 'setFavorite',
      render: ({ data, rowNum }: { rowNum: number; data: CompanyContactPersonModel }) => (
        <Tooltip
          content={rowNum === 0 ? t('Aktuálna hlavná kontaktná osoba') : t('Nastaviť ako hlavnú kontaktnú osobu')}
        >
          <div className="flex items-center">
            <button
              onClick={() => {
                handleSetFavorite(data);
              }}
              className="disabled:cursor-not-allowed"
              disabled={rowNum === 0}
            >
              {rowNum === 0 ? <LiaUserCheckSolid size={21} /> : <LiaUser className="text-neutral-400" size={21} />}
            </button>
          </div>
        </Tooltip>
      ),
    },
    {
      icon: (
        <Tooltip content={t('Upraviť')}>
          <span>
            <LiaEditSolid size={21} />
          </span>
        </Tooltip>
      ),
      key: 'edit',
      onClick: (row: CompanyContactPersonModel) => {
        setModalState({ contactPerson: row, mode: 'edit' });
      },
    },
    {
      icon: (
        <Tooltip content={t('Zmazať')}>
          <span>
            <LiaTrashSolid size={21} />
          </span>
        </Tooltip>
      ),
      key: 'delete',
      onClick: setDeleteItem,
    },
  ];

  return (
    <div className="card rounded-md">
      <div className="flex w-full items-center justify-between p-4">
        <div className="flex items-center space-x-2">
          <LiaUserTieSolid className="h-auto w-8" />
          <h2>{data.title}</h2>
        </div>
        <Button
          onClick={() => {
            setModalState({ mode: 'create' });
          }}
          label={t('Pridať kontaktnú osobu')}
          variant="raised"
        />
      </div>

      <ApiTable<CompanyContactPersonModel>
        className="rounded-t-none border-x-0 border-b-0 border-t"
        emptyMessage={data.emptyMessage}
        rowActions={rowActions}
        hasPagination={false}
        columns={columns}
        url={data.url}
      />

      <ContactPersonFormModal
        onClose={() => {
          setModalState({ mode: null });
        }}
        contactPerson={modalState.contactPerson}
        isOpen={modalState.mode !== null}
        subjectName={data.subjectName}
        url={data.url}
      />

      <DeleteModal
        textBody={t('Naozaj chcete zmazať kontaktnú osobu {name}?', { name: deleteItem?.name })}
        onClose={() => {
          setDeleteItem(undefined);
        }}
        textHeader="Zmazanie kontaktnú osobu"
        textDelete="kontaktnú osobu"
        id={deleteItem?.id}
        url={data.url}
      />
    </div>
  );
}
