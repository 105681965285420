import { useMemo, useState, PropsWithChildren } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

import useQueryClient from './useQueryClient';
import useAuthToken from '../auth/hooks/useAuthToken';

const showDevPanel = import.meta.env.VITE_APP_ENABLE_QUERY_DEV;

export default ApiProvider;

function ApiProvider({ children }: PropsWithChildren) {
  const [loaded, setLoaded] = useState(false);
  const token = useAuthToken();

  const queryClient = useQueryClient(token);

  const persistOptions = useMemo(
    () => ({
      persister: createSyncStoragePersister({
        storage: window.localStorage,
      }),
    }),
    []
  );

  return (
    <PersistQueryClientProvider onSuccess={() => setLoaded(true)} persistOptions={persistOptions} client={queryClient}>
      {loaded && children}
      {showDevPanel === 'true' && <ReactQueryDevtools initialIsOpen={false} />}
    </PersistQueryClientProvider>
  );
}
