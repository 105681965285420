import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { LiaTimesSolid } from 'react-icons/lia';
import { useRef, useEffect, ReactNode } from 'react';

import t from 'core/helpers/t';
import Button from 'core/components/Button';

interface ModalProps extends PropsWithChildren {
  icon?: ReactNode;
  title?: ReactNode;
  className?: string;
  onClose: () => void;
  primaryAction?: ReactNode;
  buttonAlign?: 'justify-center' | 'justify-end' | 'justify-start';
}

export default function Modal({
  buttonAlign = 'justify-end',
  children,
  className,
  icon,
  onClose,
  primaryAction,
  title,
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  // Detect clicks outside the modal content
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    const handleEsc = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        onClose();
      }
    };

    const preventEnterSubmit = (ev: KeyboardEvent) => {
      if (ev.key === 'Enter' && (ev.target as HTMLElement).tagName === 'INPUT') {
        ev.preventDefault();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEsc);
    document.addEventListener('keydown', preventEnterSubmit);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEsc);
      document.removeEventListener('keydown', preventEnterSubmit);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 z-[1000] flex items-center justify-center bg-neutral-500/50 p-6">
      <div
        className={clsx(
          'max-h-[90vh] w-full overflow-auto rounded bg-white shadow-lg sm:w-11/12 md:w-4/5 lg:w-[900px]',
          className
        )}
        ref={modalRef}
      >
        <div className="flex items-center justify-between p-6">
          {icon}
          <h2 className="mx-4 flex-1">{title}</h2>
          <button className="text-neutral-500 hover:text-neutral-700" onClick={onClose}>
            <LiaTimesSolid size={20} />
          </button>
        </div>
        <hr />

        {children}

        <hr />

        <div className={clsx('flex flex-col space-y-4 p-6 sm:flex-row sm:space-x-4 sm:space-y-0', buttonAlign)}>
          <Button label={t('Zrušiť')} onClick={onClose} />
          {primaryAction}
        </div>
      </div>
    </div>
  );
}
