import { useMergeRefs } from '@floating-ui/react';
import { HTMLProps, forwardRef, cloneElement, isValidElement } from 'react';

import useTooltipState from './useTooltipState';

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(function TooltipTrigger(
  { children, ...props },
  propRef
) {
  const state = useTooltipState();

  // @ts-ignore TODO: ...
  const childrenRef = children.ref;
  const ref = useMergeRefs([state.refs.setReference, propRef, childrenRef]);

  if (!isValidElement(children)) {
    return null;
  }

  return cloneElement(
    children,
    state.getReferenceProps({
      ref,
      ...props,
      ...(typeof children.props === 'object' ? children.props : {}),
      // 'data-state': state.open ? 'open' : 'closed',
    })
  );
});
