import { useState, useEffect, PropsWithChildren } from 'react';

import Spinner from '../components/Spinner';
import useGetProfile from './hooks/useGetProfile';
import AuthContext, { SetAuthContext, AuthContextState } from './context';

const ACCESS_TOKEN_KEY = 'thisIsDefinitelyNotAccessToken';

const initialAT = localStorage.getItem(ACCESS_TOKEN_KEY) ?? '';

function AuthProvider({ children }: PropsWithChildren) {
  /**
   * use
   * - undefined = loading
   * - null = not logged
   * - { user, token } = logged user
   */
  const [state, setState] = useState<AuthContextState | null | undefined>(initialAT ? undefined : null);
  const getProfile = useGetProfile();

  useEffect(() => {
    if (!state && initialAT) {
      getProfile(initialAT)
        .then((user) => {
          if (!user) {
            setState(null);
            return;
          }

          setState({
            token: {
              access_token: initialAT,
              expires_in: 36000,
              token_type: 'bearer',
            },
            user,
          });
        })
        .catch(() => {
          setState(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state !== undefined) {
      if (state) {
        localStorage.setItem(ACCESS_TOKEN_KEY, state.token.access_token);
      } else {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
      }
    }
  }, [state]);

  return (
    <SetAuthContext.Provider value={setState}>
      <AuthContext.Provider value={state}>
        {state === undefined ? <Spinner isFullScreen /> : children}
      </AuthContext.Provider>
    </SetAuthContext.Provider>
  );
}

export default AuthProvider;
