import * as y from 'yup';
import * as yup from 'yup';
import { useMemo } from 'react';

import t from 'core/helpers/t';
import { CompanyDocumentCategory } from 'app/api/requests';

export type FormData = y.InferType<ReturnType<typeof useSchema>>;

export default function useSchema() {
  return useMemo(
    () =>
      y.object({
        category_code: yup
          .mixed<CompanyDocumentCategory>()
          .oneOf(['ee', 'zp', 'contract', 'other'])
          .label(t('Kategória poznámky'))
          .nullable(),
        content: y.string().max(512).label(t('Poznámka k spoločnosti')).required(),
      }),
    []
  );
}
