import { useCompanyDetail } from 'company/components/CompanyFormModal/CompanyContext';

import t from 'core/helpers/t';
import { CompanyModel } from 'app/api/requests';

import Notes from './components/Note';
import Documents from './components/Documents';
import CompanyInfo from './components/CompanyInfo';
import ContactPersons from './components/ContactPerson';

export default function General() {
  const company = useCompanyDetail();

  const commonData = (titleKey: string, emptyMessageKey: string, path: string) => ({
    emptyMessage: t(emptyMessageKey),
    subjectName: company.company_name,
    title: t(titleKey),
    url: `companies/${company.id}/${path}`,
  });

  return (
    <>
      <CompanyInfo company={company} />
      <div className="m-6 grid grid-cols-1 gap-8 lg:grid-cols-2">
        <div className="space-y-8">
          <ContactPersons
            data={commonData(
              'Kontaktné osoby',
              'Ku danej spoločnosti nie sú evidované žiadne kontaktné osoby.',
              'contact-persons'
            )}
          />
          <div className="hidden lg:block">
            <NoteComponent company={company} />
          </div>
        </div>
        <div>
          <Documents
            data={commonData('Dokumenty', 'Ku danej spoločnosti nie sú evidované žiadne dokumenty.', 'documents')}
          />
        </div>
        <div className="lg:hidden">
          <NoteComponent company={company} />
        </div>
      </div>
    </>
  );
}

const NoteComponent = ({ company }: { company: CompanyModel }) => {
  const data = {
    emptyMessage: t('Ku danej spoločnosti nie sú evidované žiadne poznámky.'),
    subjectName: company.company_name,
    title: t('Poznámky'),
    url: `companies/${company.id}/notes`,
  };

  return <Notes data={data} />;
};
