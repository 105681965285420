import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import t from 'core/helpers/t';
import Alert from 'core/components/Alert';

export default function useDelete(
  /** Url for DELETE endpoint (service) */
  url: string,
  /** Custom callbacks */
  callbacks?: { onSuccess?: () => void; onError?: (error?: unknown) => void }
) {
  const { onError, onSuccess } = callbacks ?? {};
  const queryKey = url.replace(/^\//, '').split('/');
  const startRef = useRef<null | number>(null);
  const resolversRef = useRef<[() => void, (err: unknown) => void]>(null);

  return useMutation<unknown, unknown, number>({
    mutationKey: ['DELETE', ...queryKey],
    onError: (error) => {
      if (resolversRef.current) {
        resolversRef.current[1](error);
      }
      if (onError) {
        onError(error);
      }
    },
    onMutate: () => {
      toast.promise(
        new Promise<void>((resolve, reject) => {
          startRef.current = Date.now();
          resolversRef.current = [
            () => {
              const elapsedTime = Date.now() - (startRef.current ?? 0);
              const minimumDelay = 1000;
              const remainingTime = Math.max(minimumDelay - elapsedTime, 0);
              setTimeout(() => {
                resolve();
              }, remainingTime);
            },
            (error) => {
              reject(error instanceof Error ? error : new Error());
            },
          ];
        }),
        {
          error: {
            render: ({ data }: { data?: Error }) => {
              return <Alert message={data?.message ?? t('Chyba pri mazani.')} type="error" />;
            },
          },
          pending: {
            render: () => {
              return <Alert message={t('Mažem...')} type="pending" />;
            },
          },
          success: {
            render: () => {
              return <Alert message={t('Úspešne vymazané.')} type="success" />;
            },
          },
        }
      );
    },
    onSuccess: () => {
      if (resolversRef.current) {
        resolversRef.current[0]();
      }
      if (onSuccess) {
        onSuccess();
      }
    },
  });
}
