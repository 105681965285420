import { useState, useEffect } from 'react';
import { LiaFileAlt } from 'react-icons/lia';

import t from 'core/helpers/t';
import useSave from 'core/api/useSave';
import Form from 'core/containers/Form';
import Modal from 'core/components/Modal';
import useUser from 'core/auth/hooks/useUser';
import Field from 'core/containers/Form/Field';
import Input from 'core/components/fields/Input';
import { showToast } from 'core/components/Toast';
import Upload from 'core/components/fields/Upload';
import { fetchBlob } from 'core/hooks/useDownload';
import Switch from 'core/components/fields/Switch';
import Select from 'core/components/fields/Select';
import Textarea from 'core/components/fields/Textarea';
import useAuthToken from 'core/auth/hooks/useAuthToken';
import FieldSet from 'core/components/formTools/FieldSet';
import SubmitButton from 'core/containers/Form/SubmitButton';
import FormWrapper from 'core/components/formTools/FormWrapper';
import { CompanyDocumentModel, CompanyDocumentCategory } from 'app/api/requests';
import UserNameRenderer from 'core/containers/Table/colRenderers/UserNameRenderer';

import useFormReducer from './useFormReducer';
import useSchema, { FormData as DocumentFormData } from './useSchema';

interface FormModalProps {
  url: string;
  isOpen: boolean;
  subjectName: string;
  onClose: () => void;
  document?: CompanyDocumentModel | undefined;
}

export default function DocumentFormModal({ document, isOpen, onClose, subjectName, url }: FormModalProps) {
  const schema = useSchema();
  const currentUser = useUser();

  const documentDate = (document: CompanyDocumentModel | undefined): string => {
    let date = new Date();
    if (document?.created_at) {
      date = new Date(document.created_at);
    }

    return new Intl.DateTimeFormat('sk-SK', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
      .format(date)
      .replace(/\s/g, '');
  };

  const formReducer = useFormReducer();

  const auth = useAuthToken();
  const token = auth?.access_token;
  const [fileDownload, setFileDownload] = useState<File | null>(null);

  useEffect(() => {
    if (document) {
      const fetchFile = async () => {
        try {
          const blob = await fetchBlob(`${import.meta.env.VITE_APP_API_PATH}/${url}/file/${document.file_id}`, token);
          // Create a file object from the blob
          const file = new File([blob], document.name ?? '', { type: blob.type });
          setFileDownload(file);
        } catch (error) {
          if (error instanceof Error) {
            showToast.error(error.message);
          }
        }
      };

      // Handle the promise properly with void to avoid ESLint warning
      void fetchFile();
    } else {
      setFileDownload(null);
    }
  }, [document, token, url]);

  const { mutateAsync: modifyDocument } = useSave(document ? `POST/${url}/${document.id}` : `POST/${url}`, {
    onSuccess: document ? t('Uložené') : t('Vytvorené'),
  });

  const documentCategories: { label: string; value: CompanyDocumentCategory }[] = [
    { label: t('EE'), value: 'ee' },
    { label: t('ZP'), value: 'zp' },
    { label: t('Zmluva'), value: 'contract' },
    { label: t('ostatné'), value: 'other' },
  ];

  if (!isOpen) {
    return null;
  }

  return (
    <Form<DocumentFormData>
      onSubmit={async (values) => {
        const data = new FormData();
        const file = values.file as File;
        const blob = file.slice(0, file.size, file.type);
        const newFile = new File([blob], file.name, { type: file.type });
        data.append('title', values.title);
        data.append('note', values.note ?? '');
        data.append('category_code', values.category_code ?? '');
        data.append('important', values.important ? '1' : '0');
        data.append('file', newFile);

        await modifyDocument(data).then(() => {
          onClose();
        });
      }}
      data={{ ...document, file: fileDownload }}
      reducer={formReducer}
      schema={schema}
    >
      <Modal
        primaryAction={<SubmitButton label={t('Uložiť a zavrieť')} variant="raised" type="submit" />}
        title={t(`${subjectName} - ${document ? 'upraviť' : 'pridať'} dokument`)}
        icon={<LiaFileAlt className="size-8 text-primary-500" />}
        onClose={onClose}
      >
        <FormWrapper>
          <FieldSet>
            <div className="flex gap-2">
              <span className="md:min-w-40">{t('Meno používateľa')}</span>
              <span className="text-neutral-500">
                <span className="text-neutral-500">
                  {document?.creator_name || currentUser?.name ? (
                    <span>{document?.creator_name ?? currentUser?.name}</span>
                  ) : (
                    <UserNameRenderer userId={currentUser?.id} />
                  )}
                </span>
              </span>
            </div>
            <div className="flex gap-2">
              <span className="md:min-w-40">{t('Dátum')}</span>
              <span className="text-neutral-500">{documentDate(document)}</span>
            </div>
            <Field labelClassName="lg:min-w-40" component={Switch} name="important" />
            <div className="max-w-xs md:min-w-40">
              <Field
                labelClassName="md:min-w-40"
                options={documentCategories}
                name="category_code"
                component={Select}
                hasInlineLabel
              />
            </div>
            <Field labelClassName="md:min-w-40" component={Input} hasInlineLabel name="title" />
            <Field labelClassName="md:min-w-40" component={Upload} hasInlineLabel name="file" />
            <Field labelClassName="md:min-w-40" component={Textarea} hasInlineLabel name="note" />
          </FieldSet>
        </FormWrapper>
      </Modal>
    </Form>
  );
}
