import clsx from 'clsx';
import { FC } from 'react';

import t from 'core/helpers/t';
import Select from 'core/components/fields/Select';

export interface Props {
  to: number;
  page: number;
  from: number;
  total: number;
  pageSize: number;
  lastPage: number;
  nextPageUrl?: string;
  prevPageUrl?: string;
  setPage?: (page: number) => void;
  setPageSize?: (limit: number) => void;
}

const TableFooter: FC<Props> = ({ from, lastPage, page = 1, pageSize, setPage, setPageSize, to, total }) => {
  const paginationText = `${t('Zobrazuje sa')} ${from} - ${to} ${t('z')} ${total} ${t('záznamov')}`;

  // Generate page numbers with ellipses if needed
  const generatePageNumbers = () => {
    const pages: (number | string)[] = [];

    if (page > 2) pages.push('...');
    if (page - 1 >= 1) pages.push(page - 1);
    pages.push(page);
    if (page + 1 <= lastPage) pages.push(page + 1);
    if (page + 1 < lastPage) pages.push('...');

    return pages;
  };

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center gap-2">
        {total > 0 && (
          <div className="flex items-center space-x-2">
            {/* Previous Button */}
            <button
              className={clsx('rounded px-2 py-1', {
                'text-neutral-400 cursor-not-allowed': page === 1,
                'text-neutral-600 hover:text-neutral-900': page !== 1,
              })}
              onClick={() => setPage?.(page - 1)}
              disabled={page === 1}
            >
              &lt;
            </button>

            {/* Page Numbers */}
            {generatePageNumbers().map((pageNum, index) => (
              <button
                className={clsx(
                  'rounded px-3 py-1',
                  pageNum === page ? 'bg-primary-500 text-white' : 'text-neutral-600 hover:text-neutral-900',
                  typeof pageNum === 'string' && 'cursor-default'
                )}
                onClick={() => typeof pageNum === 'number' && setPage?.(pageNum)}
                disabled={typeof pageNum === 'string'}
                key={index}
              >
                {pageNum}
              </button>
            ))}

            {/* Next Button */}
            <button
              className={clsx('rounded px-2 py-1', {
                'text-neutral-400 cursor-not-allowed': page === lastPage,
                'text-neutral-600 hover:text-neutral-900': page !== lastPage,
              })}
              onClick={() => setPage?.(page + 1)}
              disabled={page === lastPage}
            >
              &gt;
            </button>
          </div>
        )}
      </div>

      {/* Page Size Selector */}
      <div className="flex items-center">
        <Select
          options={[
            { label: '10', value: 10 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
            { label: '200', value: 200 },
            { label: '500', value: 500 },
          ]}
          setValue={(size) => {
            if (setPageSize) {
              const finalSize = typeof size === 'number' ? size : 10;
              setPageSize(finalSize);
            }
          }}
          name="page-size"
          value={pageSize}
          isDisabled
          label=""
        />
        <span className="ml-1">{paginationText}</span>
      </div>
    </div>
  );
};

export default TableFooter;
