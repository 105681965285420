import { LiaExclamationCircleSolid } from 'react-icons/lia';

import t from 'core/helpers/t';
import useDelete from 'core/api/useDelete';

import Modal from './Modal';

interface DeleteModalProps {
  url: string;
  textBody: string;
  textDelete: string;
  textHeader: string;
  onClose: () => void;
  id: number | undefined; // opening based on provided ID
  onSuccess?: () => void;
}

export default function DeleteModal({ id, onClose, textBody, textDelete, textHeader, url }: DeleteModalProps) {
  const { isPending, mutate: deleteItem } = useDelete(url, { onSuccess: onClose });

  if (!id) {
    return null;
  }

  return (
    <Modal
      primaryAction={
        <button
          className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
          onClick={() => {
            deleteItem(id);
          }}
          disabled={isPending}
        >
          {t(`Zmazať ${textDelete}`)}
        </button>
      }
      className="text-center md:max-w-xl"
      buttonAlign="justify-center"
      title={textHeader}
      onClose={onClose}
    >
      <div className="flex flex-col items-center p-6">
        <LiaExclamationCircleSolid className="my-2 text-red-500" size={60} />
        <p className="mx-10 text-center">{textBody}</p>
      </div>
    </Modal>
  );
}
