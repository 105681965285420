import t from 'core/helpers/t';
import Badge from 'core/components/Badge';

export default function CompanyStatusRenderer({
  hasTextOnly = false,
  status,
}: {
  status: number;
  hasTextOnly?: boolean;
}) {
  switch (status) {
    case 1:
      return hasTextOnly ? t('Lead') : <Badge variant="lead">{t('Lead')}</Badge>;
    case 2:
      return hasTextOnly ? t('Príležitosť') : <Badge variant="opportunity">{t('Príležitosť')}</Badge>;
    case 3:
      return hasTextOnly ? t('Aktívny klient') : <Badge variant="active">{t('Aktívny klient')}</Badge>;
    case 4:
      return hasTextOnly ? t('Bývalý klient') : <Badge variant="former">{t('Bývalý klient')}</Badge>;
    case 5:
      return hasTextOnly ? t('Nechceme') : <Badge variant="not">{t('Nechceme')}</Badge>;
    case 6:
      return hasTextOnly ? (
        t('Zákaz zo strany klienta')
      ) : (
        <Badge variant="blocked">{t('Zákaz zo strany klienta')}</Badge>
      );
    default:
      return hasTextOnly ? '-' : <Badge>{t('-')}</Badge>;
  }
}
