import { LiaInfoCircleSolid } from 'react-icons/lia';

import t from 'core/helpers/t';
import { NoteModel } from 'app/api/requests';
import Tooltip from 'core/components/Tooltip';
import translateEnum from 'core/helpers/translateEnum.ts';
import { ColDef } from 'core/containers/Table/components/TableItem';

// Define the columns configuration
export default function useColumns(): ColDef<NoteModel>[] {
  return [
    {
      header: t('Dátum'),
      key: 'date',
      render: ({ data }) => {
        if (!data.date) {
          return '-';
        }

        const formattedDate = new Intl.DateTimeFormat('sk-SK', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        })
          .format(
            new Date(
              data.date
                .split('.')
                .reverse()
                .map((part) => part.trim())
                .join('-') // Converts "10. 11. 2024" to "2024-11-10"
            )
          )
          .replace(/\s/g, '');

        return <span>{t(formattedDate)}</span>;
      },
    },
    { field: 'creator_name', header: t('Meno používateľa') },
    { field: 'content', header: t('Poznámka') },
    {
      header: t('Kategória'),
      headerClassName: 'text-center',
      key: 'category_code',
      render: ({ data }) => (
        <div className="text-center">{translateEnum('document_category_code', data.category_code)}</div>
      ),
    },
    {
      header: t('Info'),
      headerClassName: 'text-center',
      key: 'info',
      render: ({ data }) => (
        <div className="flex justify-center">
          <Tooltip content={data.info}>
            <LiaInfoCircleSolid size={21} />
          </Tooltip>
        </div>
      ),
    },
  ];
}
