import clsx from 'clsx';
import { toast } from 'react-toastify';
import { LiaUserCircle } from 'react-icons/lia';
import { Link, useNavigate } from 'react-router-dom';
import { useRef, useState, Fragment, useEffect, ReactNode } from 'react';

import t from 'core/helpers/t';
import Alert from 'core/components/Alert';
import useUser from 'core/auth/hooks/useUser';
import useLogout from 'core/auth/hooks/useLogout';

interface Props {
  title?: ReactNode;
  breadcrumbs?: { path: string; label: string }[];
}

export default function TopBar({ breadcrumbs, title }: Props) {
  const currentUser = useUser();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const logout = useLogout();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  // TODO: "menu" component
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="fixed z-10 flex w-[calc(100%-5rem)] items-center justify-between bg-white p-4 shadow-md">
      <div className="flex items-center">
        <div className="ml-2">
          <div className="flex items-center gap-1">
            <h1>{title}</h1>
          </div>

          <div className="flex flex-row gap-2 text-xs text-neutral-400">
            {[{ label: t('Ocean'), path: '/' }, ...(breadcrumbs || [])].map((r, i, a) => (
              <Fragment key={r.path + Math.random()}>
                {i > 0 && <span> / </span>}
                {i < a.length - 1 ? <Link to={r.path}>{r.label}</Link> : <span>{r.label}</span>}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end gap-1" ref={dropdownRef}>
        <LiaUserCircle
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
          className="size-10 cursor-pointer text-primary-500"
        />
        <div>
          <small>
            {(currentUser?.name || currentUser?.surname) && (
              <>
                {currentUser.name} {currentUser.surname}
                {currentUser.email && ` (${currentUser.email})`}
              </>
            )}
          </small>
        </div>
        {dropdownOpen && (
          <div className="absolute right-0 z-50 mt-10 w-48 rounded-lg border border-gray-300 bg-white shadow-md">
            <button
              className={clsx(
                'block w-full cursor-pointer border-0 bg-transparent px-4 py-2 text-left text-gray-800 transition-colors duration-200 ease-in-out hover:bg-gray-100 disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-neutral-500 disabled:opacity-50'
              )}
              onClick={() => {
                navigate('/profile');
                setDropdownOpen(false);
              }}
              disabled
            >
              {t('Profil')}
            </button>

            <button
              onClick={() => {
                void logout().then((loggedOut) => {
                  toast(
                    <Alert
                      message={loggedOut ? t('Úspešne odhlásený.') : t('Nepodarilo sa odhlásiť.')}
                      type={loggedOut ? 'success' : 'error'}
                    />
                  );
                });
              }}
              className={clsx(
                'block w-full cursor-pointer border-0 bg-transparent px-4 py-2 text-left text-gray-800 transition-colors duration-200 ease-in-out hover:bg-gray-100 disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-neutral-500 disabled:opacity-50'
              )}
            >
              {t('Odhlásiť sa')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
