import clsx from 'clsx';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { LiaCitySolid, LiaIndustrySolid, LiaUsersCogSolid, LiaHouseDamageSolid } from 'react-icons/lia';

import t from 'core/helpers/t';
import Tooltip from 'core/components/Tooltip';
import oceanLogo from 'app/assets/svg/ocean-logo.svg';

export default function SideBar() {
  const location = useLocation();

  const getLinkClass = (path: string) => {
    return location.pathname.startsWith(path) ? 'text-accent bg-sidebarActive' : 'text-inverse';
  };

  return (
    <div className="fixed left-0 top-0 flex size-full w-20 flex-col items-center bg-primary-800 py-4 text-inverse">
      <Link className="flex w-full justify-center" to="/">
        <img className="size-12" alt="Ocean Logo" src={oceanLogo} />
      </Link>
      <nav className="mt-12 flex w-full flex-col items-center">
        <NavLink className={clsx('flex w-full justify-center py-4', getLinkClass('/suppliers'))} to="/suppliers">
          <Tooltip content={t('Dodávatelia')}>
            <LiaIndustrySolid size={30} />
          </Tooltip>
        </NavLink>

        <NavLink className={clsx('flex w-full justify-center py-4', getLinkClass('/companies'))} to="/companies">
          <Tooltip content={t('Zoznam spoločností')}>
            <LiaCitySolid size={30} />
          </Tooltip>
        </NavLink>

        <NavLink
          className={clsx('flex w-full justify-center py-4', getLinkClass('/supply-points'))}
          to="/supply-points"
        >
          <Tooltip content={t('Zoznam odberných miest')}>
            <LiaHouseDamageSolid size={30} />
          </Tooltip>
        </NavLink>

        <NavLink className={clsx('flex w-full justify-center py-4', getLinkClass('/users'))} to="/users">
          <Tooltip content={t('Správa používateľov')}>
            <LiaUsersCogSolid size={30} />
          </Tooltip>
        </NavLink>
      </nav>
    </div>
  );
}
