import * as Sentry from '@sentry/react';
import { FC, lazy, useMemo } from 'react';
import { useCompanyRoutes } from 'company';
import { ToastContainer } from 'react-toastify';

import 'app/styles/index.css';

import { useSupplyPointRoutes } from 'supplyPoint';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import { useUserRoutes } from 'user';
import { useSupplierRoutes } from 'supplier';
import Version from 'core/components/Version';
import ApiProvider from 'core/api/ApiProvider';
import AppLayout from 'app/components/AppLayout';
import AuthProvider from 'core/auth/AuthProvider';
import renderRoutes from 'app/helpers/renderRoutes';
import WatchRouteErrors from 'app/components/WatchRouteErrors';
import useFirefoxScrollbarStyles from 'core/hooks/useFirefoxScrollbarStyles';

import Dashboard from './app/pages/Dashboard';

const Login = lazy(() => import('app/pages/Login'));
const Profile = lazy(() => import('app/pages/Profile'));
const ResetPassword = lazy(() => import('app/pages/ResetPassword'));
const ForgotPassword = lazy(() => import('app/pages/ForgotPassword'));

const App: FC = () => {
  useFirefoxScrollbarStyles();
  const supplierRoutes = useSupplierRoutes();
  const companyRoutes = useCompanyRoutes();
  const userRoutes = useUserRoutes();
  const collectionRoutes = useSupplyPointRoutes();

  const routes = useMemo(
    () => [...supplierRoutes, ...companyRoutes, ...userRoutes, ...collectionRoutes],
    [companyRoutes, supplierRoutes, userRoutes, collectionRoutes]
  );

  return (
    <BrowserRouter>
      <WatchRouteErrors />
      <Version>
        <AuthProvider>
          <ApiProvider>
            <Routes>
              {/* Unauthorized routes */}
              <Route element={<Login />} path="/login" />
              <Route element={<ForgotPassword />} path="/forgot-password" />
              <Route path="/reset-password/:token" element={<ResetPassword />} />

              {/* Authorized routes */}
              <Route element={<AppLayout routes={routes} />} path="/">
                <Route element={<Dashboard />} index />

                {renderRoutes(routes)}

                <Route element={<Profile />} path="profile" />
              </Route>
            </Routes>

            <ToastContainer
              position="top-right"
              closeButton={false}
              autoClose={3000}
              hideProgressBar
              closeOnClick
              draggable
            />
          </ApiProvider>
        </AuthProvider>
      </Version>
    </BrowserRouter>
  );
};

const AppComponent = import.meta.env.VITE_APP_SENTRY_DSN ? Sentry.withProfiler(App) : App;

export default AppComponent;
