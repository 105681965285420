export default function formatTextWithLinks(text: string) {
  // Regex pattern to match valid URLs (http, https, or www followed by valid domain and optional path/query)
  const urlPattern = /(\b(https?:\/\/|www\.)[^\s/$.?#].\S*)/gi;

  // Split text by URLs and map it to elements with <a> tags
  return text.split(urlPattern).map((part, index) => {
    // Check if the part matches the URL pattern
    if (urlPattern.test(part)) {
      // Add http prefix to 'www.' links if missing
      const href = part.startsWith('http') ? part : `https://${part}`;
      return (
        <a
          className="text-primary-500 hover:text-primary-700 hover:underline"
          rel="noopener noreferrer"
          target="_blank"
          href={href}
          key={index}
        >
          {part}
        </a>
      );
    }

    // Return non-URL parts wrapped in <span>
    return <span key={index}>{part}</span>;
  });
}
