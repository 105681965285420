import type { Props as FooterProps } from 'core/containers/Table/components/TableFooter';

import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery, DefaultError, keepPreviousData } from '@tanstack/react-query';
interface ListResponse<D> {
  data: D[];
  payload?: {
    pagination?: {
      to: number;
      page: number;
      from: number;
      total: number;
      last_page: number;
      prev_page_url: string;
      first_page_url: string;
      items_per_page: number;
      nextPageUrl: null | string;
      prevPageUrl: null | string;
    };
  };
}

export default function useList<D>(url: string, useUrlState = false) {
  const [sp] = useSearchParams();
  const [page, setPage] = useState(useUrlState ? Number(sp.get('page')) : 1);
  const [pageSize, setPageSize] = useState(useUrlState ? Number(sp.get('items_per_page')) : 20);

  const meta = `${url.includes('?') ? '&' : '?'}page=${page}&items_per_page=${pageSize}`;

  useEffect(() => {
    if (!useUrlState) {
      return;
    }
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${meta}`;

    if (window.location.href !== newUrl) {
      window.history.pushState(null, '', newUrl);
    }
  }, [meta, useUrlState]);

  const { data, isFetching, isLoading } = useQuery<D, DefaultError, ListResponse<D>>({
    placeholderData: keepPreviousData,
    queryKey: url.replace(/^\//, '').split('/').concat([meta]),
    staleTime: 0,
  });

  const pagination: FooterProps = {
    from: data?.payload?.pagination?.from ?? 0,
    lastPage: data?.payload?.pagination?.last_page ?? 0,
    nextPageUrl: data?.payload?.pagination?.nextPageUrl ?? undefined,
    page: data?.payload?.pagination?.page ?? 1,
    pageSize: data?.payload?.pagination?.items_per_page ?? 20,
    prevPageUrl: data?.payload?.pagination?.prevPageUrl ?? undefined,
    setPage,
    setPageSize,
    to: data?.payload?.pagination?.to ?? 0,
    total: data?.payload?.pagination?.total ?? 0,
  };

  return { data: data?.data, isFetching, isLoading: !!isLoading, pagination };
}
