import clsx from 'clsx';
import { ReactNode } from 'react';
import { LiaTimesSolid } from 'react-icons/lia';
import 'react-toastify/dist/ReactToastify.css';

import toastTypes, { AlertTypes } from './alertTypes';

interface AlertProps {
  type: AlertTypes;
  hasIcon?: boolean;
  message: ReactNode;
  className?: string;
  onClose?: () => void;
  hasCloseButton?: boolean;
}

export default function Alert({
  className,
  hasCloseButton = true,
  hasIcon = true,
  message,
  onClose,
  type,
}: AlertProps) {
  const toastType = toastTypes[type];

  return (
    <div className={clsx('flex w-full items-center gap-4 rounded-md p-4', toastType.bg, className)}>
      {hasIcon && <div>{toastType.icon}</div>}
      <div className={clsx('flex-1 text-sm', toastType.color)}>{message}</div>
      {hasCloseButton && (
        <button className={clsx(toastType.color, 'hover:text-neutral-700')} onClick={onClose}>
          <LiaTimesSolid size={16} />
        </button>
      )}
    </div>
  );
}
