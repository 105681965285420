import { Outlet, Navigate, matchPath, useLocation } from 'react-router-dom';

import TopBar from 'app/components/TopBar';
import { Tab } from 'core/containers/Tabs';
import Sidebar from 'app/components/SideBar';
import 'app/extendYup';
import useUser from 'core/auth/hooks/useUser';
import getBreadcrumbs from 'app/helpers/getBreadcrumbs';

export default function AppLayout({ routes }: { routes: Tab[] }) {
  const user = useUser();
  const { pathname } = useLocation();

  const rootRoute = routes.find((r) => matchPath(r.path, pathname));
  const breadcrumbs = getBreadcrumbs(routes, pathname);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Sidebar />

      <main className="ml-20">
        <TopBar title={rootRoute?.label || ''} breadcrumbs={breadcrumbs} />
        <div className="pt-24">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
