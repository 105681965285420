import { useCallback } from 'react';

import useAuthToken from 'core/auth/hooks/useAuthToken';

// Fetch the blob without triggering download (can be used for input type="file")
export async function fetchBlob(url: string, token?: string): Promise<Blob> {
  const headers = new Headers();

  headers.append('Content-Type', 'application/octet-stream'); // Optional: depending on the file type

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  const response = await fetch(url, {
    headers,
    method: 'GET',
  });

  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }

  // Convert the response to a Blob (binary data)
  return await response.blob();
}

// Hook for downloading the file
export default function useDownload() {
  const auth = useAuthToken();
  const token = auth?.access_token;

  // Download file and trigger download in the browser
  return useCallback(
    (url: string, fileName: string) =>
      fetchBlob(url, token).then((blob) => {
        triggerFileDownload(blob, fileName);
      }),
    [token]
  );
}

// Function to trigger the download of a Blob
function triggerFileDownload(blob: Blob, filename: string) {
  // Create a link element and trigger the download
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename; // Specify the file name and extension
  link.click();

  // Cleanup: revoke the object URL after the download
  window.URL.revokeObjectURL(link.href);
}
