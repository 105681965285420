import clsx from 'clsx';
import { ReactNode } from 'react';

import t from 'core/helpers/t';
import useList from 'core/api/useList';

import type { Props as TableProps } from './index';

import Table from './index';
import Alert from '../../components/Alert';

interface Props<D> extends Omit<TableProps<D>, 'data'> {
  url: string;
  topLayout?: ReactNode;
  /** Enable to use browser url as source for pagination and filters */
  hasUrlQuery?: boolean;
  isNotPartner?: boolean;
  emptyMessage: ReactNode;
  emptyMessageClassName?: string;
}

function ApiTable<D extends { id?: number | string }>({
  className,
  columns,
  emptyMessage,
  emptyMessageClassName,
  hasPagination = true,
  hasUrlQuery = false,
  isNotPartner,
  rowActions = [],
  topLayout,
  url,
}: Props<D>) {
  const { data, isFetching, isLoading, pagination } = useList<D>(url, hasUrlQuery);

  const showIsNotPartnerMessage = () => {
    return (
      <>
        {isNotPartner ? (
          <Alert
            message={t('Dodávateľ nie je partnerom ENERGO.')}
            hasCloseButton={false}
            className="py-2"
            hasIcon={true}
            type="warning"
          />
        ) : null}
      </>
    );
  };

  if (!data || data.length === 0) {
    return (
      <>
        {topLayout}
        <div className={clsx('m-4 space-y-4', emptyMessageClassName)}>
          <Alert
            message={emptyMessage ?? t('Žiadne dáta.')}
            hasCloseButton={false}
            className="py-2"
            hasIcon={true}
            type="info"
          />
          {showIsNotPartnerMessage()}
        </div>
      </>
    );
  }

  return (
    <>
      <Table<D>
        isLoading={isFetching || isLoading}
        hasPagination={hasPagination}
        pagination={pagination}
        rowActions={rowActions}
        className={className}
        topLayout={topLayout}
        columns={columns}
        data={data}
        limit={0}
      />
      {showIsNotPartnerMessage()}
    </>
  );
}

export default ApiTable;
