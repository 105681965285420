import clsx from 'clsx';
import { useRef, useState, useEffect } from 'react';
import { LiaTimesSolid, LiaStickyNote } from 'react-icons/lia';

import formatTextWithLinks from 'core/helpers/formatTextWithLinks';

interface Props {
  title?: string;
  message?: string;
  className?: string;
}

export default function NoteTooltip({ className, message, title }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ left: 0, top: 0 });

  const toggleOpen = () => {
    if (!isOpen && iconRef.current) {
      const iconRect = iconRef.current.getBoundingClientRect();
      const tooltipTop = iconRect.top;
      const tooltipLeft = iconRect.left;

      setIsOpen(true);

      // After tooltip renders, calculate and update position
      setTimeout(() => {
        if (ref.current) {
          const tooltip = ref.current.getBoundingClientRect();

          const tooltipHeight = tooltip.height;
          const tooltipWidth = tooltip.width;

          // Calculate initial position
          let top = tooltipTop - tooltipHeight;
          let left = tooltipLeft - tooltipWidth;

          // Check if tooltip would go off the top edge
          if (top < 0) {
            top = tooltipTop + iconRect.height;
          }

          // Check if tooltip would go off the left edge
          if (left < 0) {
            left = tooltipLeft + iconRect.width;
          }

          // Check if tooltip would go off the right edge
          if (left + tooltipWidth > window.innerWidth) {
            left = window.innerWidth - tooltipWidth;
          }

          // Check if tooltip would go off the bottom edge
          if (top + tooltipHeight > window.innerHeight) {
            top = window.innerHeight - tooltipHeight;
          }

          setPosition({ left, top });
        }
      }, 0);
    } else {
      setIsOpen(false);
    }
  };

  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        iconRef.current &&
        !iconRef.current.contains(event.target as Node)
      ) {
        close();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('scroll', close);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', close);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', close);
    };
  }, [isOpen]);

  if (!message) {
    return null;
  }

  return (
    <>
      <div className="flex items-center justify-center">
        <button onClick={toggleOpen} ref={iconRef}>
          <LiaStickyNote size={21} />
        </button>
      </div>

      {isOpen && (
        <div style={{ left: position.left, top: position.top }} className="fixed z-[1000]">
          <div
            className={clsx('xs:w-[250px] w-[500px] overflow-auto rounded border bg-white shadow-lg', className)}
            ref={ref}
          >
            <div className="flex items-center justify-between px-4 py-2">
              <h3 className="flex items-center gap-4 font-bold">
                <LiaStickyNote size={24} /> {title}
              </h3>
              <button className="text-neutral-500 hover:text-neutral-700" onClick={close}>
                <LiaTimesSolid size={20} />
              </button>
            </div>
            <div className="px-4 py-2">{formatTextWithLinks(message)}</div>
          </div>
        </div>
      )}
    </>
  );
}
