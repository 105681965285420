// TS Type-Guards
// more https://blog.logrocket.com/how-to-use-type-guards-typescript/

export function isError(val: unknown): val is Error {
  return val instanceof Error;
}

export function isFunction(val: unknown): val is void {
  return typeof val === 'function';
}

export function isNumber(val: unknown): val is number {
  return typeof val === 'number';
}

export function isString(val: unknown): val is string {
  return typeof val === 'string';
}
