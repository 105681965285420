import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useMutation, MutationOptions } from '@tanstack/react-query';

import t from 'core/helpers/t';
import Alert from 'core/components/Alert';
import { isString, isFunction } from 'core/helpers/isType';

export default function useSave<O, I>(
  url: string,
  options: {
    onError?: MutationOptions<O, unknown, I>['onError'] | string;
    onSuccess?: MutationOptions<O, unknown, I>['onSuccess'] | string;
  } & Omit<MutationOptions<O, unknown, I>, 'onError' | 'onSuccess'>
) {
  const { onError, onMutate, onSuccess, ...restOptions } = options;

  const showToast = !isFunction(onSuccess);
  const resolversRef = useRef<[() => void, (err: unknown) => void]>(null);
  const startRef = useRef<null | number>(null);

  const successMessage = isString(onSuccess) ? onSuccess : t('Úspešne uložené.');
  const errorMessage = isString(onError) ? onError : t('Chyba pri ukladaní.');

  const result = useMutation<O, unknown, I>({
    mutationKey: url.split('/'),
    ...restOptions,
    onError: (error, ...a) => {
      if (resolversRef.current) {
        resolversRef.current[1](error);
      }
      if (onError && isFunction(onError)) {
        onError(error, ...a);
      }
    },
    onMutate: (variables) => {
      if (showToast) {
        toast.promise(
          new Promise<void>((resolve, reject) => {
            startRef.current = Date.now();
            resolversRef.current = [
              () => {
                const elapsedTime = Date.now() - (startRef.current ?? 0);
                const minimumDelay = 1000;
                const remainingTime = Math.max(minimumDelay - elapsedTime, 0);

                setTimeout(() => {
                  resolve();
                }, remainingTime);
              },
              (error) => {
                reject(error instanceof Error ? error : new Error());
              },
            ];
          }),
          {
            error: {
              render: ({ data }: { data?: Error }) => {
                return <Alert message={data?.message ?? errorMessage} type="error" />;
              },
            },
            pending: {
              render: () => {
                return <Alert message={t('Ukladám...')} type="pending" />;
              },
            },
            success: {
              render: () => {
                return <Alert message={successMessage} type="success" />;
              },
            },
          }
        );
      }
      onMutate?.(variables);
    },
    onSuccess: (...a) => {
      if (resolversRef.current) {
        resolversRef.current[0]();
      }
      if (onSuccess && isFunction(onSuccess)) {
        onSuccess(...a);
      }
    },
  });

  return result;
}
