import General from 'company/pages/CompanyDetail/tabs/General';

import t from 'core/helpers/t';
import Alert from 'core/components/Alert';

// eslint-disable-next-line react-refresh/only-export-components
const InProgress = () => (
  <Alert
    message={t('Aktuálne nie sú evidované žiadne záznamy.')}
    hasCloseButton={false}
    className="py-2"
    hasIcon={true}
    type="info"
  />
);

const companyTabs = [
  {
    element: <General />,
    label: t('Základné údaje'),
    path: 'detail',
  },
  {
    element: (
      <div className="card m-6 rounded-md p-6">
        <InProgress />
      </div>
    ),
    label: t('Odberné miesta'),
    path: 'supplyPoint-points',
  },
  {
    element: (
      <div className="card m-6 rounded-md p-6">
        <InProgress />
      </div>
    ),
    label: t('Dodávky'),
    path: 'deliveries',
  },
  {
    element: (
      <div className="card m-6 rounded-md p-6">
        <InProgress />
      </div>
    ),
    label: t('Služby'),
    path: 'services',
  },
  {
    element: (
      <div className="card m-6 rounded-md p-6">
        <InProgress />
      </div>
    ),
    label: t('Príležitosti'),
    path: 'opportunities',
  },
  {
    element: (
      <div className="card m-6 rounded-md p-6">
        <InProgress />
      </div>
    ),
    label: t('Faktúry'),
    path: 'invoices',
  },
  {
    element: (
      <div className="card m-6 rounded-md p-6">
        <InProgress />
      </div>
    ),
    label: t('Aktivity'),
    path: 'activities',
  },
];

export function useCompanyTabs() {
  return companyTabs;
}
