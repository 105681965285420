import * as yup from 'yup';

import t from './t';

export const validateMobileNumber = (label: string = 'Telefón', minLength: number = 9, maxLength: number = 15) =>
  yup
    .string()
    .label(label)
    .required()
    .test('valid-characters', t(`${label} obsahuje neplatné znaky`), (value) => {
      if (!value) return false; // Ensure value exists
      // Allow numbers, spaces, +, -, /, and ()
      const allowedCharacters = /^[0-9+/\- ]*$/;
      return allowedCharacters.test(value);
    })
    .test('valid-format', t(`${label} obsahuje nesprávny formát`), (value) => {
      if (!value) return false; // Ensure value exists
      // Normalize by removing spaces
      const normalizedValue = value.replace(/\s/g, '');
      // Disallow consecutive separators like // or --
      const invalidSeparators = /[+/-]{2,}/;
      if (invalidSeparators.test(normalizedValue)) return false;

      // Remove valid separators for final format check
      const digitsOnly = normalizedValue.replace(/[+/-]/g, '');
      // Check if it starts with + or 00, and has the correct length
      return (
        /^(\+|00)?[0-9]+$/.test(normalizedValue.replace(/[^0-9+]/g, '')) &&
        digitsOnly.length >= minLength &&
        digitsOnly.length <= maxLength
      );
    });

export const validateDocument = (label: string = 'Dokument') =>
  yup
    .mixed()
    .label(label)
    .required()
    .test('fileType', t('Neplatný typ súboru'), (value) => {
      if (value instanceof File) {
        const validTypes = [
          /* eslint-disable @cspell/spellchecker */

          // Document formats
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'text/plain',
          'application/rtf',
          'application/vnd.oasis.opendocument.text',
          'application/vnd.oasis.opendocument.spreadsheet',
          'text/csv',
          'application/xml',
          'text/xml',
          'text/html',
          'application/vnd.ms-outlook',
          'message/rfc822',
          'application/vnd.wordperfect',
          'application/x-mspublisher',
          'application/vnd.visio',
          'application/vnd.ms-xpsdocument',

          // Image formats
          'image/jpeg',
          'image/jpg',
          'image/png',
          'image/gif',
          'image/tiff',
          'image/bmp',
          'image/webp',
          'image/svg+xml',
          'image/vnd.adobe.photoshop',
          'image/vnd.adobe.illustrator',
          'application/postscript',
          'image/heif',
          'image/x-adobe-dng',
          'image/raw',

          // Archive formats
          'application/zip',
          'application/x-zip-compressed',
          'application/x-rar-compressed',
          'application/x-7z-compressed',
          'application/x-tar',

          // Video formats
          'video/mp4',
          'video/x-msvideo',
          'video/quicktime',
          'video/x-ms-wmv',
          'video/x-matroska',
          'video/x-flv',
          'video/mpeg',

          // Audio formats
          'audio/mpeg',
          'audio/wav',
          'audio/aac',
          'audio/flac',
          'audio/ogg',
          'audio/x-ms-wma',

          // Other formats
          'application/x-pvprj',
        ];

        if (!value.type) {
          const validExt = ['psd', 'pvprj'];
          const fileExtension = value.name.split('.').pop()?.toLowerCase();
          return !!(fileExtension && validExt.includes(fileExtension));
        }

        return validTypes.includes(value.type);
      }
      return false;
    })
    .test('fileSize', t('Maximálna povolená veľkosť súboru je 2 MB'), (value) => {
      if (value instanceof File) {
        return value.size <= 2 * 1024 * 1024; // 2MB size check
      }
      return false;
    });
