export const search = (text: string, searchQuery: string): boolean => {
  if (!searchQuery) {
    return true; // Return all results if no query
  }

  // Normalize and prepare strings for comparison, remove diacritics
  const removeDiacritics = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const normalizedQuery = removeDiacritics(searchQuery.toLowerCase());
  const normalizedText = removeDiacritics(text.toLowerCase());

  // Check for exact and partial matches
  const exactMatch = normalizedText.localeCompare(normalizedQuery, 'sk', { sensitivity: 'base' }) === 0;
  const partialMatch = normalizedText.includes(normalizedQuery);

  return exactMatch || partialMatch;
};
