import { lazy } from 'react';

import t from 'core/helpers/t';
import Spinner from 'core/components/Spinner';

/* eslint-disable react-refresh/only-export-components */
const ProvisionRules = lazy(() => import('./ProvisionRules'));
const General = lazy(() => import('./General'));
const Products = lazy(() => import('./Products'));
const DailyPrices = lazy(() => import('./DailyPrices'));
/* eslint-enable react-refresh/only-export-components */

const supplierTabs = [
  {
    element: <General />,
    label: t('Základné údaje'),
    path: `detail`,
  },
  {
    element: <Products />,
    label: t('Produkty'),
    path: 'products/*',
    routes: [{ label: t('Detail Produktu'), path: ':productId' }],
  },
  {
    element: <ProvisionRules />,
    label: t('Provízne pravidlá'),
    path: 'provision-rules/*',
  },
  {
    element: <DailyPrices />,
    label: t('Denné ceny'),
    path: 'daily-prices/*',
  },
  {
    element: (
      <div className="card m-6 rounded-md p-6">
        <Spinner />
      </div>
    ),
    label: t('Prehľady'),
    path: 'overviews',
  },
];

// using hook bcs of potential re-render requirements
export default function useSupplierTabs() {
  return supplierTabs;
}
