import * as y from 'yup';
import * as yup from 'yup';
import { useMemo } from 'react';

import t from 'core/helpers/t';
import useUser from 'core/auth/hooks/useUser';

export type FormData = yup.InferType<ReturnType<typeof useSchema>>;

// Define the common address schema
export function useAddressSchema() {
  return useMemo(
    () =>
      yup.object({
        country_id: yup.number().required().label(t('Štát')).default(8375),
        number: yup.string().required().label(t('Číslo domu')),
        psc: yup.string().required().label(t('PSČ')),
        street_name: yup.string().required().label(t('Ulica')),
        town: yup.string().required().label(t('Mesto')),
      }),
    []
  );
}

export default function useSchema() {
  const addressSchema = useAddressSchema();
  const currentUser = useUser();
  return useMemo(() => {
    // Define the main schema
    return yup.object({
      address: addressSchema.default({}),
      assigned_partner: yup.string().label('Partner/tipér').nullable(),
      assigned_salesperson_id: yup.number().positive().label('Pridelený obchodník').nullable(),
      bank: yup.string().label(t('Banka')).nullable(),
      company_name: yup.string().label(t('Názov spoločnosti')).required(),
      coordinator_id: yup.number().positive().label('Koordinátor').nullable(),
      created_by: yup.number().required().default(currentUser?.id),
      current_status: yup.number().positive().label('Aktuálny stav').nullable().default(1),
      dic: yup.string().nullable().label(t('DIČ')),
      iban: yup
        .string()
        .nullable()
        .label(t('IBAN'))
        .test('iban-code', t('IBAN obsahuje nesprávny kód banky'), function (value) {
          if (value && value.length >= 8) {
            const { bank } = this.parent;
            const bankCode = value[4] === '0' ? value.substring(5, 8) : value.substring(4, 8);

            return bank === bankCode;
          }
          return true;
        }),
      ic_dph: yup
        .string()
        .nullable()
        .label(t('IČ DPH'))
        .when('is_vat_payer', {
          is: true,
          then: (schema) => schema.required(t('IČ DPH je povinné, ak je platca DPH')),
        }),
      ico: yup.string().label(t('IČO')).required(),
      invoice_due_days: yup.number().positive().label('Splatnosť faktúr').nullable().default(14),
      is_vat_payer: yup.boolean().default(true).label(t('Platca DPH')),
      mail_address: addressSchema.default({}).when('same_as_company_address', {
        is: false,
        then: (schema) => schema.required().label(t('Korešpondenčná adresa')),
      }),
      registered_in: yup.string().nullable().label(t('Zapísaná v registri')),
      same_as_company_address: yup.boolean().nullable().label(t('Rovnaká ako sídlo spoločnosti')),
      service_contract_date: y
        .string()
        .nullable()
        .label(t('Dátum zmluvy o poskytovaní služieb'))
        .test('service_contract_date-format', t('Nesprávny formát dátumu (DD. MM. YYYY)'), (value) => {
          if (value) {
            return /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])( (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9])?$/.test(
              value
            );
          }
          return true;
        }),
      sk_nace: yup.string().label(t('Činnosť (SK NACE)')).required(),
      swift_bic: yup.string().nullable().label(t('SWIFT/BIC')),
    });
  }, [addressSchema, currentUser?.id]);
}
