import { lazy, useMemo } from 'react';

import t from 'core/helpers/t';

// eslint-disable-next-line react-refresh/only-export-components
const UserList = lazy(() => import('user/pages/UserList'));

export function useUserRoutes() {
  return useMemo(() => [{ element: <UserList />, label: t('Správa používateľov'), path: 'users' }], []);
}
