import { Schema, InferType } from 'yup';

export default function castSchema<S extends Schema>(
  schema: S,
  data?: Partial<InferType<S>> | Record<string, unknown>
) {
  // assert = false - bcs of nullable().required()

  return schema.cast(data ?? {}, { assert: false, stripUnknown: true });
}
