import clsx from 'clsx';
import { useRef, useState, useEffect } from 'react';
import { LiaTimesCircle, LiaAngleDownSolid } from 'react-icons/lia';

import t from 'core/helpers/t';
import Label from 'core/components/Label';
import { search } from 'core/helpers/search';
import { SkNaceEnumModel } from 'app/api/requests';

export interface SkNaceSelectProps {
  name: string;
  value?: string;
  label?: string;
  isBold?: boolean;
  errorText?: string;
  isSearch?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  truncateWidth?: string;
  labelClassName?: string;
  hasInlineLabel?: boolean;
  options: SkNaceEnumModel;
  setValue: (value: string) => void;
}

export default function SkNaceSelect({
  errorText,
  hasInlineLabel = false,
  isBold = false,
  isDisabled = false,
  isRequired = false,
  isSearch = true,
  label,
  labelClassName,
  name,
  options,
  setValue,
  truncateWidth,
  value,
}: SkNaceSelectProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<SkNaceEnumModel>(options);
  const [isOpen, setIsOpen] = useState(false);

  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef, isOpen]);

  const handleClear = () => {
    setSearchQuery(''); // Clear the input field
    if (searchRef.current) {
      searchRef.current.focus(); // Focus the input field
    }
  };

  // Map to store refs for each option
  const optionRefs = useRef(new Map());

  useEffect(() => {
    // If value is a single selection (not multiple)
    if (!Array.isArray(value)) {
      const targetRef = optionRefs.current.get(value);
      if (targetRef) {
        targetRef.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }
    }
  }, [value, isOpen]);

  // Recursive render function
  const renderItems = (options: SkNaceEnumModel) => {
    return options.map((option) => (
      <div key={option.id}>
        {/* Render the main option */}
        <button
          className={clsx('flex w-full items-center justify-start px-3 py-1 text-left', {
            'bg-neutral-100': option.name === value,
            'cursor-default text-skNace': !option.selectable,
            'cursor-not-allowed opacity-50 bg-neutral-200': isDisabled,
            'cursor-pointer': !isDisabled && option.selectable,
            'hover:bg-neutral-200 font-bold': option.selectable,
          })}
          onClick={() => {
            if (!isDisabled && option.selectable && option.name) {
              setValue(option.name);
              setIsOpen(false);
            }
          }}
          ref={(el) => {
            // Store reference to each button in the map
            optionRefs.current.set(option.name, el);
          }}
          type="button"
        >
          {option.id}
          <span>&nbsp;</span>
          {option.name}
        </button>

        {/* Recursively render children if they exist */}
        {option.children && option.children.length > 0 && <div className="ml-4">{renderItems(option.children)}</div>}
      </div>
    ));
  };

  const findSelected = (options?: SkNaceEnumModel): string => {
    if (!options) {
      return t('Zoznam je prázdny...');
    }

    const searchOptions = (options: SkNaceEnumModel): null | string => {
      for (const option of options) {
        if (option.name === value && option.selectable && option.name) {
          return option.name;
        }

        if (option.children && option.children.length > 0) {
          const found = searchOptions(option.children);
          if (found) {
            return found;
          }
        }
      }

      return null; // No match found in this branch
    };

    const result = searchOptions(options);

    if (result) {
      return result;
    }

    if (value) {
      setValue('');
    }

    return t('Vyberte...');
  };

  // Use useEffect to trigger filtering whenever searchQuery changes
  useEffect(() => {
    if (searchQuery.length < 3) {
      setFilteredOptions(options);
    } else {
      setFilteredOptions(filterSelectableChildren(options, searchQuery));
    }
  }, [searchQuery, options]);

  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const [dropdownAbove, setDropdownAbove] = useState(false);

  useEffect(() => {
    function updateDropdownPosition() {
      if (selectRef.current) {
        const rect = selectRef.current.getBoundingClientRect();
        const dropdownHeight = 200; // Approximate height of dropdown (adjust as needed)
        const windowHeight = window.innerHeight;

        // Check if there's enough space below; otherwise, open above
        if (windowHeight - rect.bottom < dropdownHeight && rect.top > dropdownHeight) {
          setDropdownAbove(true);
        } else {
          setDropdownAbove(false);
        }
      }
    }

    // Call on mount and on every scroll/resize
    updateDropdownPosition();
    window.addEventListener('resize', updateDropdownPosition);
    window.addEventListener('scroll', updateDropdownPosition, true);

    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
      window.removeEventListener('scroll', updateDropdownPosition, true);
    };
  }, [isOpen, selectRef]);

  return (
    <div className={hasInlineLabel ? 'flex space-x-2' : 'block'} ref={selectRef}>
      {label && (
        <Label
          className={clsx('mt-2', labelClassName)}
          hasInlineLabel={hasInlineLabel}
          isRequired={isRequired}
          isBold={isBold}
          label={label}
          name={name}
        />
      )}

      <div className="relative flex-1">
        <button
          className={clsx(
            'flex w-full items-center justify-between rounded-md border bg-neutral-200 px-3 py-2 text-neutral-700 focus:outline-none focus:ring-2 focus:ring-primary-500',
            isDisabled ? 'cursor-not-allowed bg-neutral-200 opacity-50' : 'cursor-pointer',
            {
              'border-red-500': errorText,
            }
          )}
          onClick={() => {
            if (!isDisabled) {
              setIsOpen((prev) => !prev);
            }
          }}
          disabled={isDisabled}
          type="button"
        >
          <span className={clsx('block truncate text-left', truncateWidth)} title={findSelected(options)}>
            {findSelected(options)}
          </span>
          <LiaAngleDownSolid className="ml-2 shrink-0" />
        </button>

        {isOpen && (
          <div
            className={clsx(
              'card absolute z-50 w-full overflow-y-auto rounded-md bg-white',
              dropdownAbove ? 'bottom-full' : 'top-8',
              { 'shadow-lg': isSearch }
            )}
          >
            {isSearch && (
              <div className="sticky top-0 z-10 bg-white">
                <div className="relative flex items-center space-x-2">
                  <input
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    className="w-full border-b px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500"
                    placeholder={t('Vyhľadajte...')}
                    disabled={isDisabled}
                    value={searchQuery}
                    ref={searchRef}
                    type="text"
                  />
                  {searchQuery && (
                    <button
                      className="absolute inset-y-0 right-0 flex items-center pr-3 text-select-clear opacity-25 hover:opacity-100"
                      disabled={isDisabled}
                      onClick={handleClear}
                      type="button"
                    >
                      <LiaTimesCircle size={20} />
                    </button>
                  )}
                </div>
              </div>
            )}

            <div className="max-h-52 overflow-y-auto">{renderItems(filteredOptions)}</div>
          </div>
        )}
        {errorText && <div className="text-error mt-1">{errorText}</div>}
      </div>
    </div>
  );
}

// Function to filter selectable children (only the last level can be selectable)
const filterSelectableChildren = (arr: SkNaceEnumModel, searchQuery: string) => {
  return arr.reduce<SkNaceEnumModel>((acc, item) => {
    if (item.children && item.children.length > 0) {
      // Recursively filter children
      const filteredChildren = filterSelectableChildren(item.children, searchQuery);
      if (filteredChildren.length > 0) {
        acc.push({ ...item, children: filteredChildren });
      }
    } else if (item.selectable) {
      // Only include items at the last level that are selectable and match the search query
      const value = String(item.id);

      if ((item.name && search(item.name, searchQuery)) || search(value, searchQuery)) {
        acc.push(item);
      }
    }
    return acc;
  }, []);
};
