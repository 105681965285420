import type { Data, FormAction } from '@serusko/reform/dist/context';

import { Schema } from 'yup';
import { useMemo, useEffect, useCallback, PropsWithChildren } from 'react';
import REForm, { getRequired, useFormSelect, useValidation, getDefaultFormReducer } from '@serusko/reform';

import castSchema from 'core/helpers/castSchema';

import type { FormState } from './props';

export type { FormState };

interface Props<D extends Data> extends PropsWithChildren {
  id?: string;
  schema: Schema;
  data?: unknown;
  reducer?: (s: FormState<D>, a: FormAction<D>) => FormState<D>;
  onSubmit: ((values: D) => Promise<void>) | ((values: D) => void);
}

export default function Form<D extends Data>({ children, data, id, onSubmit, reducer, schema }: Props<D>) {
  const getRequiredFn = useCallback((data: Data) => getRequired(schema.describe({ value: data })), [schema]);
  const validation = useValidation(schema);
  const inits = useMemo(() => castSchema(schema, data ?? {}), [data, schema]);

  const defaultReducer = useMemo(
    () => (ps: FormState<D>, a: FormAction<D>) => {
      const r = getDefaultFormReducer<D>(validation, getRequiredFn);

      const s = { ...ps, schema };

      return reducer ? r(reducer(s as FormState<D>, a), a) : r(s, a);
    },
    [validation, getRequiredFn, schema, reducer]
  );

  return (
    <REForm<D>
      getRequired={getRequiredFn}
      // @ts-ignore TODO: typing
      reducer={defaultReducer}
      validation={validation}
      initialValues={inits}
      onSubmit={onSubmit}
      id={id}
    >
      {import.meta.env.DEV && <Debug />}
      {children}
    </REForm>
  );
}

const Debug = () => {
  const s = useFormSelect((s) => s);

  useEffect(() => {
    // @ts-ignore TODO: resolve precommit tsconfig
    window.getFormState = () => {
      return s;
    };

    return () => {
      // @ts-ignore TODO: resolve precommit tsconfig
      window.getFormState = undefined;
    };
  });

  return null;
};
