import t from 'core/helpers/t';
import NoteTooltip from 'core/components/NoteTooltip';
import { ColDef } from 'core/containers/Table/components/TableItem';
import { CompanyContactPersonModelRequest } from 'app/api/requests';
import ContactPersonRenderer from 'core/containers/Table/colRenderers/ContactPersonRenderer';

// Define the columns configuration
export default function useColumns(): ColDef<CompanyContactPersonModelRequest>[] {
  return [
    {
      header: t('Meno'),
      key: 'name',
      render: ({ data }) => <ContactPersonRenderer contactPerson={data} hasFullNameOnly />,
    },
    { field: 'mobile', header: t('Telefón') },
    {
      header: t('E-mail'),
      key: 'email',
      render: ({ data }) => <span className="break-all">{data.email}</span>,
    },
    { field: 'position', header: t('Funkcia') },
    {
      header: t('Poznámka'),
      headerClassName: 'text-center',
      key: 'note',
      render: ({ data }) => (
        <NoteTooltip
          title={t(`${[data.name, data.surname, data.title].join(' ')} - poznámka ku kontaktnej osobe`)}
          message={data.note}
        />
      ),
    },
  ];
}
