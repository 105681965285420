import t from 'core/helpers/t';
import useDetail from 'core/api/useDetail';
import { UserModel } from 'app/api/requests';
import Spinner from 'core/components/Spinner';

interface UserNameRendererProps {
  userId?: number;
}

export default function UserNameRenderer({ userId }: UserNameRendererProps) {
  const { data, isLoading } = useDetail<UserModel>(`/users/${userId}`);

  if (isLoading) {
    return <Spinner />;
  }

  const fullName = [data?.name, data?.surname].filter(Boolean).join(' ').trim();
  const displayName = fullName || data?.email || data?.login || t('Neznámy používateľ');

  return <span className="flex truncate">{displayName}</span>;
}
