import { lazy, useMemo } from 'react';

import t from 'core/helpers/t';

import useSupplierTabs from './pages/SupplierDetail/tabs/useSupplierTabs';

// eslint-disable-next-line react-refresh/only-export-components
const SupplierList = lazy(() => import('supplier/pages/SupplierList'));
// eslint-disable-next-line react-refresh/only-export-components
const SupplierDetail = lazy(() => import('supplier/pages/SupplierDetail'));

export function useSupplierRoutes() {
  const supplierTabs = useSupplierTabs();

  return useMemo(
    () => [
      { element: <SupplierList />, label: t('Zoznam dodávateľov'), path: 'suppliers' },
      {
        element: <SupplierDetail />,
        label: t('Detail dodávateľa'),
        path: 'suppliers/:supplierId/*',
        prevBreadcrumb: 'suppliers',
        routes: supplierTabs,
      },
    ],
    [supplierTabs]
  );
}
