import { useCallback } from 'react';
import FormAction from '@serusko/reform/dist/FormAction';

import { FormState } from 'core/containers/Form';

import { FormData } from './useSchema';

export default function useFormReducer() {
  return useCallback((state: FormState<FormData>, action: FormAction<FormData>) => {
    if (action.type === 'setValue' && action.name === 'file') {
      return {
        ...state,
        values: {
          ...state.values,
          // @ts-ignore TODO: generic getter
          title: state.values.title ? state.values.title : action.value?.name || null,
        },
      };
    }

    return state;
  }, []);
}
