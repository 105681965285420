import { useMemo } from 'react';
import { AnyObjectSchema } from 'yup';
import { useFormSelect } from '@serusko/reform';

export default function useSchemaMaxLen(fieldName: string) {
  // @ts-ignore TODO: ...
  const schema: AnyObjectSchema | undefined = useFormSelect((s) => s.schema);

  return useMemo(() => {
    try {
      // @ts-ignore TODO: ...
      const tests = schema && schema.describe({ context: {}, parent: {}, value: {} }).fields[fieldName].tests;
      if (tests && Array.isArray(tests)) {
        const test = (tests as { name: string; params: Record<string, number> }[]).find((t) => t.name === 'max');

        if (test) {
          return Number(test.params.max) || undefined;
        }
      }
    } catch {
      //
    }
    return undefined;
  }, [fieldName, schema]);
}
