import { FormRender } from '@serusko/reform';
import { LiaCitySolid } from 'react-icons/lia';
import { useMemo, useState, useEffect } from 'react';
import FormState from '@serusko/reform/dist/FormState';

import t from 'core/helpers/t';
import useSave from 'core/api/useSave';
import useList from 'core/api/useList';
import Form from 'core/containers/Form';
import Modal from 'core/components/Modal';
import Button from 'core/components/Button';
import useUser from 'core/auth/hooks/useUser';
import Field from 'core/containers/Form/Field';
import useEnumList from 'core/api/useEnumList';
import Input from 'core/components/fields/Input';
import Select from 'core/components/fields/Select';
import Switch from 'core/components/fields/Switch';
import { BankOption, TownOption } from 'app/types/props';
import FieldSet from 'core/components/formTools/FieldSet';
import { UserModel, CompanyModel } from 'app/api/requests';
import SubmitButton from 'core/containers/Form/SubmitButton';
import SkNaceSelect from 'core/components/fields/SkNaceSelect';
import FormWrapper from 'core/components/formTools/FormWrapper';
import UserNameRenderer from 'core/containers/Table/colRenderers/UserNameRenderer';

import useFormReducer from './useFormReducer';
import useSchema, { FormData } from './useSchema';

interface FormModalProps {
  isOpen: boolean;
  company?: CompanyModel;
  onClose: (id?: number) => void;
}

export default function CompanyFormModal({ company, isOpen, onClose }: FormModalProps) {
  const [townOptions, setTownOptions] = useState<TownOption[]>([]);
  const currentUser = useUser();
  const schema = useSchema();

  const { mutateAsync: saveSupplier } = useSave<CompanyModel, FormData>(
    company ? `PATCH/companies/${company.id}` : 'POST/companies',
    { onSuccess: company ? t('Uložené') : t('Vytvorené') }
  );

  const inputData = useMemo(() => {
    const resolvedCompany = {
      ...company,
      address: company?.address ?? {},
      mail_address: company?.mail_address ?? {},
    };

    const sameAsCompanyAddress =
      JSON.stringify({ ...resolvedCompany.address, id: 0 }) ===
      JSON.stringify({ ...resolvedCompany.mail_address, id: 0 });

    return {
      ...resolvedCompany,
      same_as_company_address: sameAsCompanyAddress,
    };
  }, [company]);

  const { data: statuses, isLoading: isStatusEnumLoading } = useEnumList('company_business_status');
  const { data: countries, isLoading: isCountryEnumLoading } = useEnumList('countries');
  const { data: skNaceEnum, isLoading: isSkNaceEnumLoading } = useEnumList('sk_nace');
  const { data: towns, isLoading: isTownEnumLoading } = useEnumList('towns');
  const { data: banks, isLoading: isBankEnumLoading } = useEnumList('banks');

  const { data: usersData, isLoading: usersDataAreLoading } = useList<UserModel>('users', false);

  useEffect(() => {
    if (!isTownEnumLoading) {
      // Generate the initial options
      const options = towns
        .filter((town) => town.name && town.city_id !== undefined)
        .map((town) => ({
          label: town.name || '',
          value: town.name || '',
          zip_code: town.zip_code,
        }));

      // Create addressTown and mailAddressTown if company data exists
      const additionalOptions = [];
      if (company) {
        if (company.address?.town) {
          additionalOptions.push({
            label: company.address.town,
            value: company.address.town,
          });
        }
        if (company.mail_address?.town) {
          additionalOptions.push({
            label: company.mail_address.town,
            value: company.mail_address.town,
          });
        }
      }

      // Merge options and remove duplicates by value
      const mergedOptions = [...options, ...additionalOptions].filter(
        (option, index, self) => index === self.findIndex((o) => o.value === option.value)
      );

      setTownOptions(mergedOptions);
    }
  }, [company, towns, isTownEnumLoading]);

  const userOptions = useMemo(() => {
    if (!usersDataAreLoading) {
      if (!usersData) return [];

      return usersData.map((user) => {
        const nameParts = [user.title, user.name, user.surname].filter(Boolean);
        const fullName = nameParts.length > 1 ? nameParts.join(' ') : (user.email ?? user.login);

        return {
          label: fullName,
          value: user.id,
        };
      });
    }
  }, [usersData, usersDataAreLoading]);

  const [bankOptions, setBankOptions] = useState<BankOption[]>([]);

  useEffect(() => {
    if (!isBankEnumLoading) {
      const options = banks
        .filter((bank) => bank.label?.name && bank.value !== undefined)
        .map((bank) => ({
          label: bank.label?.name || t('Neznáma banka'),
          swift_code: bank.label?.swift_code,
          value: bank.value as string,
        }));
      setBankOptions(options);
    }
  }, [banks, isBankEnumLoading]);

  const formReducer = useFormReducer(bankOptions, townOptions);

  if (!isOpen) {
    return null;
  }

  return (
    <Form<FormData>
      onSubmit={async (values) => {
        const data = {
          ...values,
          mail_address: values.same_as_company_address ? values.address : values.mail_address,
        };
        await saveSupplier(data).then((res) => {
          onClose(res.id);
        });
      }}
      reducer={formReducer}
      data={inputData}
      schema={schema}
    >
      <Modal
        title={t(company ? `${company.company_name} - úprava údajov spoločnosti` : 'Vytvorenie novej spoločnosti')}
        primaryAction={<SubmitButton label={t('Uložiť a zavrieť')} variant="raised" type="submit" />}
        icon={<LiaCitySolid className="size-8 text-primary-500" />}
        onClose={onClose}
      >
        <FormWrapper>
          <FieldSet title={t('Základné údaje')} cols={3}>
            <div className="lg:col-span-2">
              <Field labelClassName="lg:min-w-32" name="company_name" component={Input} hasInlineLabel />
            </div>

            <div className="flex justify-between gap-4">
              <Field component={Input} hasInlineLabel name="ico" />
              <div>
                <Button label={t('Finstat')} isDisabled />
              </div>
            </div>

            <Field labelClassName="lg:min-w-32" component={Input} hasInlineLabel name="dic" />

            <Field labelClassName="lg:min-w-16" component={Input} hasInlineLabel name="ic_dph" />

            <Field labelClassName="lg:min-w-20" name="is_vat_payer" component={Switch} />

            <div className="col-span-3 gap-4 space-y-4">
              <Field labelClassName="lg:min-w-32" name="registered_in" component={Input} hasInlineLabel />
              <Field
                isDisabled={isSkNaceEnumLoading}
                truncateWidth="max-w-[41rem]"
                labelClassName="lg:min-w-32"
                component={SkNaceSelect}
                options={skNaceEnum}
                hasInlineLabel
                name="sk_nace"
              />
            </div>
          </FieldSet>
          <FieldSet title={t('Bankové údaje')} cols={3}>
            <div className="col-span-2">
              <Field labelClassName="lg:min-w-20" component={Input} hasInlineLabel name="iban" />
            </div>
            <div className="col-span-2">
              <Field
                labelClassName="lg:min-w-20"
                truncateWidth="max-w-96"
                options={bankOptions}
                component={Select}
                hasInlineLabel
                name="bank"
                isSearch
              />
            </div>

            <Field labelClassName="lg:min-w-20" component={Input} name="swift_bic" hasInlineLabel />
          </FieldSet>

          <FieldSet title={t('Sídlo / fakturačná adresa')} cols={6}>
            <div className="lg:col-span-4">
              <Field labelClassName="lg:min-w-20" name="address.street_name" component={Input} hasInlineLabel />
            </div>
            <div className="lg:col-span-2">
              <Field labelClassName="lg:min-w-20" name="address.number" component={Input} hasInlineLabel />
            </div>
            <div className="lg:col-span-3">
              <Field
                isDisabled={isTownEnumLoading}
                labelClassName="lg:min-w-20"
                options={townOptions}
                name="address.town"
                component={Select}
                hasInlineLabel
                hasInsert
                isSearch
              />
            </div>

            <Field labelClassName="lg:min-w-12" name="address.psc" component={Input} hasInlineLabel />
            <div className="lg:col-span-2">
              <Field
                isDisabled={isCountryEnumLoading}
                labelClassName="lg:min-w-20"
                name="address.country_id"
                truncateWidth="max-w-32"
                options={countries}
                component={Select}
                hasInlineLabel
                isSearch
              />
            </div>
          </FieldSet>

          <FieldSet title={t('Korešpondenčná adresa')} cols={6}>
            <div className="col-span-6">
              <Field name="same_as_company_address" component={Switch} />
            </div>
            <FormRender<FormData> condition={(s: FormState<FormData>) => !s.values.same_as_company_address}>
              <div className="lg:col-span-4">
                <Field name="mail_address.street_name" labelClassName="lg:min-w-20" component={Input} hasInlineLabel />
              </div>
              <div className="lg:col-span-2">
                <Field labelClassName="lg:min-w-20" name="mail_address.number" component={Input} hasInlineLabel />
              </div>
              <div className="lg:col-span-3">
                <Field
                  isDisabled={isTownEnumLoading}
                  labelClassName="lg:min-w-20"
                  name="mail_address.town"
                  options={townOptions}
                  component={Select}
                  hasInlineLabel
                  hasInsert
                  isSearch
                />
              </div>

              <Field labelClassName="lg:min-w-12" name="mail_address.psc" component={Input} hasInlineLabel />

              <div className="lg:col-span-2">
                <Field
                  isDisabled={isCountryEnumLoading}
                  name="mail_address.country_id"
                  labelClassName="lg:min-w-20"
                  truncateWidth="max-w-32"
                  options={countries}
                  component={Select}
                  hasInlineLabel
                  isSearch
                />
              </div>
            </FormRender>
          </FieldSet>

          <FieldSet title={t('Obchodné informácie')} cols={2}>
            <Field
              isDisabled={isStatusEnumLoading}
              labelClassName="lg:min-w-32"
              truncateWidth="max-w-56"
              name="current_status"
              options={statuses}
              component={Select}
              hasInlineLabel
            />
            <Field
              labelClassName="lg:min-w-56"
              name="service_contract_date"
              component={Input}
              hasInlineLabel
              type="date"
            />
            <Field
              isDisabled={usersDataAreLoading}
              name="assigned_salesperson_id"
              labelClassName="lg:min-w-32"
              truncateWidth="max-w-56"
              options={userOptions}
              component={Select}
              hasInlineLabel
              isSearch
            />
            <Field
              labelClassName="lg:min-w-32"
              truncateWidth="max-w-56"
              name="assigned_partner"
              component={Select}
              hasInlineLabel
              options={[]}
              hasInsert
            />
            <Field
              isDisabled={usersDataAreLoading}
              labelClassName="lg:min-w-32"
              truncateWidth="max-w-56"
              name="coordinator_id"
              options={userOptions}
              component={Select}
              hasInlineLabel
              isSearch
            />
            <div className="flex items-center gap-2">
              <span className="lg:min-w-32">{t('Vytvoril')}</span>
              {(company && company.created_by) || currentUser?.id ? (
                <span className="text-neutral-500">
                  <UserNameRenderer userId={company?.created_by ?? currentUser?.id} />
                </span>
              ) : null}
            </div>
          </FieldSet>
        </FormWrapper>
      </Modal>
    </Form>
  );
}
