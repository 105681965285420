import {
  LiaClock,
  LiaCheckCircle,
  LiaTimesCircle,
  LiaInfoCircleSolid,
  LiaExclamationCircleSolid,
} from 'react-icons/lia';

const alertTypes = {
  error: {
    bg: 'bg-toast-red-100',
    color: 'text-toast-red-500',
    icon: <LiaTimesCircle className="text-toast-red-500" size={28} />,
  },
  info: {
    bg: 'bg-toast-primary-100',
    color: 'text-toast-primary-500',
    icon: <LiaInfoCircleSolid className="text-primary-500" size={28} />,
  },
  pending: {
    bg: 'bg-toast-neutral-100',
    color: 'text-toast-neutral-500',
    icon: <LiaClock className="text-neutral-500" size={28} />,
  },
  success: {
    bg: 'bg-toast-green-100',
    color: 'text-toast-green-500',
    icon: <LiaCheckCircle className="text-toast-green-500" size={28} />,
  },
  warning: {
    bg: 'bg-toast-yellow-100',
    color: 'text-toast-yellow-500',
    icon: <LiaExclamationCircleSolid className="text-toast-yellow-500" size={28} />,
  },
};

export default alertTypes;

export type AlertTypes = keyof typeof alertTypes;
