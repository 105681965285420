import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { showToast } from 'core/components/Toast';
import translateEnum from 'core/helpers/translateEnum';

/**
 * Watch for router status errors and display toast
 */
export default function WatchRouteErrors() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.errorCode) {
      showToast.error(translateEnum('messages', location.state.errorCode));
    }
  }, [location.state]);

  return null;
}
