import clsx from 'clsx';
import { PropsWithChildren } from 'react';

export default function FieldSet({
  children,
  className,
  cols = 1,
  gap = 4,
  title,
}: { gap?: number; cols?: number; title?: string; className?: string } & PropsWithChildren) {
  return (
    <div className={clsx('flex flex-col gap-4 px-6', className)}>
      {title && (
        <div className="flex w-full items-center">
          <h3 className="mr-2 font-semibold text-primary-500">{title}</h3>
        </div>
      )}
      <div
        className={clsx('grid grid-cols-1', {
          'gap-0': gap === 0,
          'gap-1': gap === 1,
          'gap-2': gap === 2,
          'gap-3': gap === 3,
          'gap-4': gap === 4,
          'grid-cols-2': cols === 2,
          'grid-cols-3': cols === 3,
          'grid-cols-4': cols === 4,
          'grid-cols-5': cols === 5,
          'grid-cols-6': cols === 6,
        })}
      >
        {children}
      </div>
    </div>
  );
}
