import { useState } from 'react';
import { LiaEdit, LiaEditSolid, LiaTrashSolid } from 'react-icons/lia';

import t from 'core/helpers/t';
import Button from 'core/components/Button';
import { NoteModel } from 'app/api/requests';
import Tooltip from 'core/components/Tooltip';
import Switch from 'core/components/fields/Switch';
import Select from 'core/components/fields/Select';
import DeleteModal from 'core/components/DeleteModal';
import ApiTable from 'core/containers/Table/ApiTable';
import { GeneralComponentProps } from 'app/types/props';

import useColumns from './useColumns';
import NoteFormModal from './NoteFormModal';

interface HeaderCardProps {
  data: GeneralComponentProps;
}

export default function Notes({ data }: HeaderCardProps) {
  const columns = useColumns();

  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [editItem, setEditItem] = useState<NoteModel | undefined>(undefined);
  const [deleteItem, setDeleteItem] = useState<NoteModel | undefined>(undefined);

  const [isCheckedAllDocuments, setIsCheckedAllDocuments] = useState<boolean>(true);
  const [isCategorySelected, setIsCategorySelected] = useState<null | string | string[]>('all');

  const rowActions = [
    {
      icon: (
        <Tooltip content={t('Upraviť')}>
          <span>
            <LiaEditSolid size={21} />
          </span>
        </Tooltip>
      ),
      key: 'edit',
      onClick: setEditItem,
    },
    {
      icon: (
        <Tooltip content={t('Zmazať')}>
          <span>
            <LiaTrashSolid size={21} />
          </span>
        </Tooltip>
      ),
      key: 'delete',
      onClick: setDeleteItem,
    },
  ];

  return (
    <div className="card rounded-md">
      <div className="flex w-full items-center justify-between p-4">
        <div className="flex items-center space-x-2">
          <LiaEdit className="h-auto w-8" />
          <h2>{data.title}</h2>
        </div>
        <Button
          onClick={() => {
            setCreateModalOpen(true);
          }}
          label={t(`Pridať poznámku`)}
          variant="raised"
        />
      </div>
      <div className="mb-2 flex w-full items-center">
        <div className="scale-75">
          <Switch
            label={t('Všetky poznámky k spoločnosti')}
            setValue={setIsCheckedAllDocuments}
            value={isCheckedAllDocuments}
            name="all_company_documents"
            variant="left"
            isDisabled
          />
        </div>
        <div className="scale-75">
          <Select
            options={[{ label: t('Všetky kategórie'), value: 'all' }]}
            setValue={(v) => setIsCategorySelected(v)}
            value={isCategorySelected}
            name="category"
            isDisabled
            label=""
          />
        </div>
      </div>

      <ApiTable<NoteModel>
        className="rounded-t-none border-x-0 border-b-0 border-t"
        emptyMessage={data.emptyMessage}
        rowActions={rowActions}
        hasPagination={false}
        columns={columns}
        url={data.url}
      />

      <NoteFormModal
        onClose={() => {
          setEditItem(undefined);
          setCreateModalOpen(false);
        }}
        isOpen={!!editItem || isCreateModalOpen}
        subjectName={data.subjectName}
        note={editItem}
        url={data.url}
      />

      <DeleteModal
        onClose={() => {
          setDeleteItem(undefined);
        }}
        textBody={t(`Naozaj chcete zmazať poznámku?`)}
        textHeader={t('Zmazanie poznámky')}
        textDelete={t('poznámku')}
        id={deleteItem?.id}
        url={data.url}
      />
    </div>
  );
}
