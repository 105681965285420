import clsx from 'clsx';
import { ReactNode } from 'react';

import type { ColDef, RowAction } from './components/TableItem';

import Header from './components/TableHeader';
import TableItem from './components/TableItem';
import Spinner from '../../components/Spinner';
import TableFooter, { Props as TableFooterProps } from './components/TableFooter';

export interface Props<D> {
  data: D[];
  limit?: number;
  className?: string;
  isLoading?: boolean;
  columns: ColDef<D>[];
  topLayout?: ReactNode;
  hasPagination?: boolean;
  rowActions?: RowAction<D>[];
  pagination?: TableFooterProps;
}

function Table<D extends { id?: number | string }>({
  className,
  columns,
  data,
  hasPagination,
  isLoading,
  pagination,
  rowActions = [],
  topLayout,
}: Props<D>) {
  return (
    <>
      <div className={clsx('card overflow-x-auto overflow-y-hidden rounded-md', className)}>
        <div className={clsx({ 'border-b': topLayout })}>{topLayout}</div>
        <table className="min-w-full border-collapse">
          <Header hasActions={!!rowActions.length} columns={columns} />

          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length + (rowActions.length ? 1 : 0)} className="border-t p-7">
                  <Spinner />
                </td>
              </tr>
            ) : (
              <>
                {Array.isArray(data) &&
                  data.map((item, index) => (
                    <TableItem<D>
                      key={(pagination?.page ?? 0) * (pagination?.pageSize ?? 0) + index}
                      rowActions={rowActions}
                      columns={columns}
                      rowNum={index}
                      data={item}
                    />
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>

      {/* Conditional rendering for pagination */}
      {hasPagination && pagination && <TableFooter {...pagination} />}
    </>
  );
}

export default Table;
