import * as y from 'yup';
import { useMemo } from 'react';

import t from 'core/helpers/t';
import { validateMobileNumber } from 'core/helpers/useValidation';

export type FormData = y.InferType<ReturnType<typeof useSchema>>;

export default function useSchema() {
  return useMemo(
    () =>
      y.object().shape({
        company_position: y.boolean().label(t('Koná v mene spoločnosti')).default(false),
        company_position_type: y.string().nullable(),
        email: y.string().label(t('E-mail')).nullable().email(t('Nesprávny formát e-mailovej adresy.')),
        manager: y.object().shape({
          manager_group: y.boolean().label(t('Spoločne')).default(false),
          manager_single: y.boolean().label(t('Samostatne')).default(false),
          signature_right: y.boolean().label(t('Podpisové právo spoločnosti')).default(false),
          state_in_documents: y.boolean().label(t('Uvádzať osobu v zmluvách a dokumentoch')).default(false),
        }),
        mobile: validateMobileNumber('Telefón'),
        name: y.string().label(t('Meno')).required(),
        note: y.string().max(128).label(t('Poznámka')).nullable(),
        position: y.string().label(t('Funkcia')).nullable(),
        surname: y.string().label(t('Priezvisko')).required(),
        title: y.string().label(t('Titul')).nullable(),
      }),
    []
  );
}
