export default function formatDate(date?: null | string): null | string {
  if (date) {
    return new Intl.DateTimeFormat('sk-SK', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    })
      .format(new Date(date))
      .replace(/\s/g, '');
  }

  return null;
}
