import { LiaEdit } from 'react-icons/lia';

import t from 'core/helpers/t';
import useSave from 'core/api/useSave';
import Form from 'core/containers/Form';
import Modal from 'core/components/Modal';
import useUser from 'core/auth/hooks/useUser';
import Field from 'core/containers/Form/Field';
import Select from 'core/components/fields/Select';
import Textarea from 'core/components/fields/Textarea';
import FieldSet from 'core/components/formTools/FieldSet';
import SubmitButton from 'core/containers/Form/SubmitButton';
import FormWrapper from 'core/components/formTools/FormWrapper';
import { NoteModel, CompanyDocumentCategory } from 'app/api/requests';
import UserNameRenderer from 'core/containers/Table/colRenderers/UserNameRenderer';

import useSchema, { FormData } from './useSchema';

interface FormModalProps {
  url: string;
  isOpen: boolean;
  note?: NoteModel;
  subjectName: string;
  onClose: () => void;
}

export default function NoteFormModal({ isOpen, note, onClose, subjectName, url }: FormModalProps) {
  const schema = useSchema();

  const currentUser = useUser();
  const currentDate = new Intl.DateTimeFormat('sk-SK', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }).format(new Date());

  const { mutateAsync: modifyNote } = useSave(note ? `PATCH/${url}/${note.id}` : `POST/${url}`, {
    onSuccess: note ? t('Uložené') : t('Vytvorené'),
  });

  const documentCategories: { label: string; value: CompanyDocumentCategory }[] = [
    { label: t('EE'), value: 'ee' },
    { label: t('ZP'), value: 'zp' },
    { label: t('Zmluva'), value: 'contract' },
    { label: t('ostatné'), value: 'other' },
  ];

  if (!isOpen) {
    return null;
  }

  return (
    <Form<FormData>
      onSubmit={async (values) =>
        modifyNote(values).then(() => {
          onClose();
        })
      }
      schema={schema}
      data={note}
    >
      <Modal
        primaryAction={<SubmitButton label={t('Uložiť a zavrieť')} variant="raised" type="submit" />}
        title={t(`${subjectName} - ${note ? 'upraviť' : 'pridať'} poznámku`)}
        icon={<LiaEdit className="size-8 text-primary-500" />}
        onClose={onClose}
      >
        <FormWrapper>
          <FieldSet>
            <div className="flex gap-2">
              <span className="md:min-w-40">{t('Meno používateľa')}</span>
              <span className="text-neutral-500">
                {note?.creator_name || currentUser?.name ? (
                  <span>{note?.creator_name ?? currentUser?.name}</span>
                ) : (
                  <UserNameRenderer userId={currentUser?.id} />
                )}
              </span>
            </div>
            <div className="flex gap-2">
              <span className="md:min-w-40">{t('Dátum')}</span>
              <span className="text-neutral-500">{note?.date?.replace(/\s/g, '') ?? currentDate}</span>
            </div>
            <div className="max-w-xs md:min-w-40">
              <Select
                setValue={() => {
                  // No-op: function intentionally left blank
                }}
                options={[{ label: t('Verejná'), value: 'public' }]}
                labelClassName="md:min-w-40"
                label={t('Typ poznámky')}
                hasInlineLabel
                value="public"
                name="type"
              />
            </div>
            <div className="max-w-xs md:min-w-40">
              <Field
                labelClassName="md:min-w-40"
                options={documentCategories}
                name="category_code"
                component={Select}
                hasInlineLabel
              />
            </div>
            <Field labelClassName="md:min-w-40" component={Textarea} hasInlineLabel name="content" />
          </FieldSet>
        </FormWrapper>
      </Modal>
    </Form>
  );
}
