import clsx from 'clsx';
import { ReactNode, ButtonHTMLAttributes } from 'react';

import Spinner from '../Spinner';

export interface Props {
  label?: string;
  icon?: ReactNode;
  /**
   * Custom styling
   * TODO: disable
   */
  className?: string;
  /** Display loading status */
  isLoading?: boolean;
  onClick?: () => void;
  appendIcon?: ReactNode;
  /**
   * @default "default"
   */
  variant?: 'default' | 'raised';
  isDisabled?: boolean | undefined;
  /**
   * @default "button"
   */
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

export default function Button({
  appendIcon,
  className,
  icon,
  isDisabled,
  isLoading = false,
  label,
  onClick,
  type = 'button',
  variant = 'default',
}: Props) {
  return (
    <button
      className={clsx(
        'flex items-center gap-2 rounded-md px-6 py-2 transition duration-200 ease-in-out focus:outline-none focus:ring-0',
        {
          'bg-button-default-bg hover:bg-button-default-hover text-button-800': variant === 'default' && !isDisabled,
          'bg-button-default-disabled cursor-not-allowed text-neutral-400': isDisabled ?? isLoading,
          'bg-primary-500 hover:bg-primary-700 text-white': variant === 'raised' && !isDisabled,
        },
        className
      )}
      onClick={!isDisabled && !isLoading ? onClick : undefined}
      disabled={isDisabled ?? isLoading}
      type={type}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {icon && <span>{icon}</span>}
          {label}
          {appendIcon && <span>{appendIcon}</span>}
        </>
      )}
    </button>
  );
}
