import { ReactNode } from 'react';
import { toast, ToastContentProps } from 'react-toastify';

import Alert from '../Alert';
import { AlertTypes } from '../Alert/alertTypes';
import './toastify-reset.css';

type CProps = ToastContentProps<{ type: AlertTypes; message: ReactNode }>;

export function showToast(
  content: ReactNode,
  { type }: { type: AlertTypes; id?: number | string } = { type: 'warning' }
) {
  toast(C, { data: { message: content, type } });
}

showToast.error = (msg: ReactNode, id?: number | string) => showToast(msg, { id, type: 'error' });
showToast.success = (msg: ReactNode, id?: number | string) => showToast(msg, { id, type: 'success' });
showToast.info = (msg: ReactNode, id?: number | string) => showToast(msg, { id, type: 'info' });
showToast.warning = (msg: ReactNode, id?: number | string) => showToast(msg, { id, type: 'warning' });

// eslint-disable-next-line react-refresh/only-export-components
function C({ closeToast, data }: CProps) {
  return <Alert message={data.message} onClose={closeToast} type={data.type} />;
}
