import t from 'core/helpers/t';
import Tooltip from 'core/components/Tooltip';
import NoteTooltip from 'core/components/NoteTooltip';
import translateEnum from 'core/helpers/translateEnum';
import { CompanyDocumentModel } from 'app/api/requests';
import { ColDef } from 'core/containers/Table/components/TableItem';
import UserNameRenderer from 'core/containers/Table/colRenderers/UserNameRenderer';

// Define the columns configuration
export default function useColumns(): ColDef<CompanyDocumentModel>[] {
  return [
    {
      header: t('Dátum pridania'),
      key: 'created_at',
      render: ({ data }) => {
        const formattedDate = new Intl.DateTimeFormat('sk-SK', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        })
          .format(new Date(data.created_at ?? Date.now()))
          .replace(/\s/g, '');

        return <span>{t(formattedDate)}</span>;
      },
    },
    {
      header: t('Meno používateľa'),
      key: 'creator_name',
      render: ({ data }) => <UserNameRenderer userId={data.user_id} />,
    },
    {
      header: t('Názov dokumentu'),
      key: 'title',
      render: ({ data }) => (
        <Tooltip content={t(`${data.name} (${((data.size ?? 0) / 1024).toFixed(2)} kB)`)}>
          <div className="md:max-w-32">{data.title}</div>
        </Tooltip>
      ),
    },
    {
      header: t('Kategória'),
      headerClassName: 'text-center',
      key: 'category_code',
      render: ({ data }) => (
        <div className="text-center">{translateEnum('document_category_code', data.category_code)}</div>
      ),
    },
    {
      header: t('Poznámka'),
      headerClassName: 'text-center',
      key: 'note',
      render: ({ data }) => <NoteTooltip title={t(`${data.title} - poznámka k dokumentu`)} message={data.note} />,
    },
  ];
}
