import { useMemo } from 'react';
import { QueryClient } from '@tanstack/react-query';

import t from 'core/helpers/t';
import messages from 'app/enums/messages';
import { LoginResponse } from 'core/auth/hooks/useLogin';

import translateEnum from '../helpers/translateEnum';

type Method = 'DELETE' | 'PATCH' | 'POST' | 'PUT';

export default function useQueryClient(token: LoginResponse | undefined) {
  const client = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            async mutationFn(variables) {
              const key = this.mutationKey;

              if (!key) {
                throw new Error('Provide mutation key');
              }

              const method = isReqMethod(key[0]) ? key[0] : undefined;
              const url = key.join('/').replace(`${method}/`, '');

              const headers = new Headers();
              if (token) {
                headers.append('Authorization', `Bearer ${token.access_token}`);
              }
              if (variables instanceof FormData) {
                // headers.append('Content-Type',  undefined);
              } else {
                headers.append('Content-Type', 'application/json');
              }
              headers.append('Accept', 'application/json');

              let body: FormData | null | string | undefined;
              let id: number | undefined = undefined;

              if (isNumber(variables)) {
                id = variables;
              } else if (variables instanceof FormData) {
                body = variables;
              } else if (variables && typeof variables === 'object') {
                body = JSON.stringify(variables);
              }

              let link = `${import.meta.env.VITE_APP_API_PATH}/${url}`;
              if (id) {
                link += `/${id}`;
              }

              const response = await fetch(link, { body, headers, method: method ?? 'POST' });

              if (response.status === 401) {
                // logout
              }

              let data: unknown;

              try {
                data = await response.json();
              } catch {
                throw new Error(t('Odpoveď servera sa nepodarilo spracovať'));
              }

              // Type guard to ensure data is an object with the expected structure
              const isResponseWithMeta = (
                obj: unknown
              ): obj is { message?: string; meta?: { 'response-info'?: { code?: keyof typeof messages } } } =>
                typeof obj === 'object' && obj !== null;

              const msg = isResponseWithMeta(data)
                ? (data.message ?? translateEnum('messages', data.meta?.['response-info']?.code ?? 'unknown'))
                : translateEnum('messages', 'unknown');

              if (!response.ok) {
                let err;
                if (response.status === 500) {
                  err = new Error(t('Chyba servera (Viac: "{more}")', { more: msg }));
                } else {
                  err = new Error(msg);
                }

                throw err;
              }

              // invalidate all queries on the way
              // [suppliers]
              // [suppliers, 1]
              // [suppliers, 1, contact-persons]
              // [suppliers, 1, contact-persons, 2]
              //  await queryClient.invalidateQueries({ queryKey: [...queryKey, id.toString()] });
              await client.invalidateQueries({
                predicate: (q) => {
                  const k = q.queryKey.join('/');

                  return url.split('/').some((_, i, a) => k.includes(a.slice(0, i + 1).join('/')));
                },
              });

              return data;
            },
          },
          queries: {
            queryFn: async ({ queryKey, signal }): Promise<unknown> => {
              const key = queryKey.join('/').replace('/?', '?');

              const headers = new Headers();
              if (token) {
                headers.append('Authorization', `Bearer ${token.access_token}`);
              }

              const url = `${import.meta.env.VITE_APP_API_PATH}/${key}`;

              const response = await fetch(url, { headers, signal });

              return response.json();
            },
            staleTime: 5 * 60 * 1000,
          },
        },
      }),
    [token]
  );

  return client;
}

function isNumber(val: unknown): val is number {
  return typeof val === 'number';
}

function isReqMethod(key: unknown): key is Method {
  const k = typeof key === 'string' ? key : '';
  return ['DELETE', 'PATCH', 'POST', 'PUT'].includes(k);
}
