import clsx from 'clsx';
import { ReactNode } from 'react';

interface Props {
  name: string;
  label: ReactNode;
  isBold?: boolean;
  className?: string;
  isRequired?: boolean;
  hasInlineLabel?: boolean;
}

function Label({ className, hasInlineLabel = false, isBold = false, isRequired = false, label, name }: Props) {
  return (
    <label
      className={clsx(
        'leading-6 text-neutral-900',
        {
          'font-bold': isBold,
          'min-w-10': hasInlineLabel,
        },
        className
      )}
      htmlFor={`input-${name}`}
    >
      {label}
      {isRequired && <span className="inline pl-1 text-red-500">*</span>}
    </label>
  );
}

export default Label;
