import { useState } from 'react';
import { toast } from 'react-toastify';
import { LiaStar, LiaEditSolid, LiaStarSolid, LiaTrashSolid, LiaFileAltSolid, LiaDownloadSolid } from 'react-icons/lia';

import t from 'core/helpers/t';
import useSave from 'core/api/useSave';
import Alert from 'core/components/Alert';
import Button from 'core/components/Button';
import Tooltip from 'core/components/Tooltip';
import useDownload from 'core/hooks/useDownload';
import Switch from 'core/components/fields/Switch';
import Select from 'core/components/fields/Select';
import DeleteModal from 'core/components/DeleteModal';
import ApiTable from 'core/containers/Table/ApiTable';
import { GeneralComponentProps } from 'app/types/props';
import { RowAction } from 'core/containers/Table/components/TableItem';
import { CompanyDocumentModel, CompanyDocumentCategory } from 'app/api/requests';

import useColumns from './useColumns';
import DocumentFormModal from './DocumentFormModal';

interface Props {
  data: GeneralComponentProps;
}

export default function Documents({ data }: Props) {
  const columns = useColumns();
  const download = useDownload();

  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [editItem, setEditItem] = useState<CompanyDocumentModel | undefined>(undefined);
  const [deleteItem, setDeleteItem] = useState<CompanyDocumentModel | undefined>(undefined);

  const [importantItem, setImportantItem] = useState<CompanyDocumentModel | undefined>(undefined);
  const { mutateAsync: modifyDocument } = useSave(`POST/${data.url}/${importantItem?.id}`, {
    onSuccess: importantItem?.important
      ? t('Dokument bol nastavený ako dôležitý')
      : t('Dokument bol zrušený ako dôležitý'),
  });

  const [isCheckedAllDocuments, setIsCheckedAllDocuments] = useState<boolean>(true);
  const [isCategorySelected, setIsCategorySelected] = useState<null | string | string[]>('all');

  const documentCategories: { label: string; value: 'all' | CompanyDocumentCategory }[] = [
    { label: t('Všetky kategórie'), value: 'all' },
    { label: t('EE'), value: 'ee' },
    { label: t('ZP'), value: 'zp' },
    { label: t('Zmluva'), value: 'contract' },
    { label: t('ostatné'), value: 'other' },
  ];

  const rowActions: RowAction<CompanyDocumentModel>[] = [
    {
      key: 'setMain',
      render: ({ data }: { rowNum: number; data: CompanyDocumentModel }) => (
        <Tooltip content={data.important ? t('Zrušiť z dôležitých dokumentov') : t('Nastaviť ako dôležitý dokument')}>
          <button
            onClick={() => {
              data.important = !data.important;
              setImportantItem(data);
              setTimeout(() => modifyDocument(data), 1);
            }}
            className="disabled:cursor-not-allowed"
          >
            {data.important ? <LiaStarSolid size={21} /> : <LiaStar className="text-neutral-400" size={21} />}
          </button>
        </Tooltip>
      ),
    },
    {
      icon: (
        <Tooltip content={t('Stiahnuť')}>
          <span>
            <LiaDownloadSolid size={21} />
          </span>
        </Tooltip>
      ),
      key: 'download',
      onClick: ({ file_id, name }) => {
        download(`${import.meta.env.VITE_APP_API_PATH}/${data.url}/file/${file_id}`, name ?? '').catch(() => {
          toast.error(<Alert message={t('Nepodarilo sa stiahnuť súbor.')} type="error" />);
        });
      },
    },
    {
      icon: (
        <Tooltip content={t('Upraviť')}>
          <span>
            <LiaEditSolid size={21} />
          </span>
        </Tooltip>
      ),
      key: 'edit',
      onClick: setEditItem,
    },
    {
      icon: (
        <Tooltip content={t('Zmazať')}>
          <span>
            <LiaTrashSolid size={21} />
          </span>
        </Tooltip>
      ),
      key: 'delete',
      onClick: setDeleteItem,
    },
  ];

  return (
    <div className="card rounded-md">
      <div className="flex w-full items-center justify-between p-4">
        <div className="flex items-center space-x-2">
          <LiaFileAltSolid className="h-auto w-8" />
          <h2>{data.title}</h2>
        </div>
        <Button
          onClick={() => {
            setCreateModalOpen(true);
          }}
          label={t(`Pridať dokument`)}
          variant="raised"
        />
      </div>
      <div className="mb-2 flex w-full items-center">
        <div className="scale-75">
          <Switch
            label={t('Všetky dokumenty k spoločnosti')}
            setValue={setIsCheckedAllDocuments}
            value={isCheckedAllDocuments}
            name="all_company_documents"
            variant="left"
            isDisabled
          />
        </div>
        <div className="scale-75">
          <Select
            setValue={(v) => setIsCategorySelected(v)}
            options={documentCategories}
            value={isCategorySelected}
            name="category"
            isDisabled
            label=""
          />
        </div>
      </div>

      <ApiTable<CompanyDocumentModel>
        className="rounded-t-none border-x-0 border-b-0 border-t"
        emptyMessage={data.emptyMessage}
        rowActions={rowActions}
        hasPagination={false}
        columns={columns}
        url={data.url}
      />

      <DocumentFormModal
        onClose={() => {
          setEditItem(undefined);
          setCreateModalOpen(false);
        }}
        isOpen={!!editItem || isCreateModalOpen}
        subjectName={data.subjectName}
        document={editItem}
        url={data.url}
      />

      <DeleteModal
        onClose={() => {
          setDeleteItem(undefined);
        }}
        textBody={t(`Naozaj chcete zmazať dokument?`)}
        textHeader={t('Zmazanie dokumentu')}
        textDelete={t('dokument')}
        id={deleteItem?.id}
        url={data.url}
      />
    </div>
  );
}
