import clsx from 'clsx';
import { ReactNode } from 'react';

import Label from 'core/components/Label';

interface Props {
  name: string;
  error?: string;
  isBold?: boolean;
  label?: ReactNode;
  isRequired: boolean;
  isDisabled: boolean;
  options: RadioOption[];
  value: number | string;
  labelClassName?: string;
  inputClassName?: string;
  hasInlineLabel?: boolean;
  setValue: (v: number | string) => void;
}

interface RadioOption {
  label: string;
  value: number | string;
}

export default function RadioGroup({
  error,
  hasInlineLabel = false,
  inputClassName,
  isBold = false,
  isDisabled = false,
  isRequired = false,
  label,
  labelClassName,
  name,
  options,
  setValue,
  value,
}: Props) {
  return (
    <div className="flex w-full flex-col gap-2">
      <div
        className={clsx('flex w-full justify-start gap-2', {
          'flex-col': !hasInlineLabel,
          'flex-row': hasInlineLabel,
        })}
      >
        <Label
          hasInlineLabel={hasInlineLabel}
          className={labelClassName}
          isRequired={isRequired}
          isBold={isBold}
          label={label}
          name={name}
        />

        <div className="w-full grow">
          <div className={clsx('relative flex grow gap-8', inputClassName)}>
            {options.map((option) => (
              <label
                className={clsx('flex items-center gap-2 text-neutral-700', {
                  'cursor-pointer': !isDisabled,
                })}
                key={option.value}
              >
                <div className="relative flex items-center justify-center">
                  <div
                    className={clsx('size-5 rounded-full border border-neutral-300 bg-neutral-200', {
                      'border-red-500': error,
                    })}
                  ></div>

                  {value === option.value && <div className="absolute size-3.5 rounded-full bg-primary-500" />}
                </div>

                <input
                  onChange={() => {
                    setValue(option.value);
                  }}
                  checked={value === option.value}
                  disabled={isDisabled}
                  value={option.value}
                  className="hidden"
                  type="radio"
                />

                {option.label}
              </label>
            ))}
          </div>
          {error && <div className="text-error mt-1">{error}</div>}
        </div>
      </div>
    </div>
  );
}
