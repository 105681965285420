import { lazy, useMemo } from 'react';

import t from 'core/helpers/t';

// eslint-disable-next-line react-refresh/only-export-components
const SupplyPointList = lazy(() => import('supplyPoint/pages/SupplyPointList'));

export function useSupplyPointRoutes() {
  return useMemo(
    () => [{ element: <SupplyPointList />, label: t('Zoznam odberných miest'), path: 'supply-points' }],
    []
  );
}
