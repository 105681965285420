import { Field as OField } from '@serusko/reform';
import { Data } from '@serusko/reform/dist/context';
import FieldProps from '@serusko/reform/dist/components/FieldProps';

import useSchemaLabel from './useSchemaLabel';

export default function Field<D extends Data = Data>({ label, name, ...props }: FieldProps<D>) {
  const schemaLabel = useSchemaLabel(name);
  return <OField {...props} label={label || schemaLabel} name={name} />;
}
