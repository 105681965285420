import { useCallback } from 'react';
import * as Sentry from '@sentry/react';

import useAuthToken from './useAuthToken';
import useSetAuthState from './useSetAuthState';

export default function useLogout() {
  const setAuthState = useSetAuthState();
  const auth = useAuthToken();
  const token = auth?.access_token;

  return useCallback(() => {
    const headers = new Headers();

    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    const requestOptions = { headers, method: 'POST' };

    return fetch(`${import.meta.env.VITE_APP_API_PATH}/auth/logout`, requestOptions)
      .then((r) => {
        if (r.ok) {
          Sentry.setUser(null);

          setAuthState(null);
          return true;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
  }, [setAuthState, token]);
}
