import { FormRender } from '@serusko/reform';
import { LiaUserTieSolid } from 'react-icons/lia';
import { useMemo, useState, useEffect } from 'react';
import FormState from '@serusko/reform/dist/FormState';

import t from 'core/helpers/t';
import useSave from 'core/api/useSave';
import Form from 'core/containers/Form';
import Modal from 'core/components/Modal';
import useDetail from 'core/api/useDetail';
import Field from 'core/containers/Form/Field';
import Input from 'core/components/fields/Input';
import Switch from 'core/components/fields/Switch';
import FieldSet from 'core/components/formTools/FieldSet';
import RadioGroup from 'core/components/fields/RadioGroup';
import SubmitButton from 'core/containers/Form/SubmitButton';
import Select, { Option } from 'core/components/fields/Select';
import FormWrapper from 'core/components/formTools/FormWrapper';
import { EnumModel, CompanyContactPersonModel } from 'app/api/requests';

import useSchema, { FormData } from './useSchema';

interface FormModalProps {
  url: string;
  isOpen: boolean;
  subjectName: string;
  onClose: () => void;
  contactPerson?: CompanyContactPersonModel | undefined;
}

export default function ContactPersonFormModal({ contactPerson, isOpen, onClose, subjectName, url }: FormModalProps) {
  const schema = useSchema();

  const { mutateAsync: modifyContactPerson } = useSave(
    contactPerson ? `PATCH/${url}/${contactPerson.id}` : `POST/${url}`,
    {
      onSuccess: contactPerson ? t('Uložené') : t('Vytvorené'),
    }
  );

  const inputData = useMemo(() => {
    return {
      ...contactPerson,
      company_position: contactPerson?.manager?.manager_group || contactPerson?.manager?.manager_single,
      company_position_type: contactPerson?.manager?.manager_group
        ? 'group'
        : contactPerson?.manager?.manager_single
          ? 'single'
          : null,
      manager: {
        manager_group: contactPerson?.manager?.manager_group ?? false,
        manager_single: contactPerson?.manager?.manager_single ?? false,
        signature_right: contactPerson?.manager?.signature_right ?? false,
        state_in_documents: contactPerson?.manager?.state_in_documents ?? false,
      },
    };
  }, [contactPerson]);

  /**
   * System Enums
   */
  const { data: positionEnum, isLoading: isPositionEnumLoading } = useDetail<{ contact_person_positions: EnumModel }>(
    `/system/enums?type=contact_person_positions`
  );

  const [positionOptions, setPositionOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (!isPositionEnumLoading && positionEnum?.contact_person_positions) {
      // Create additional options if supplier data exists
      const additionalOptions: Option[] = [];
      if (contactPerson?.position) {
        additionalOptions.push({
          label: contactPerson.position,
          value: contactPerson.position,
        });
      }

      const enumOptions: Option[] = positionEnum.contact_person_positions
        .filter((option) => !!option.label)
        .map((option) => ({ label: option.label || '', value: option.label || '' }));

      // Merge options and remove duplicates by value
      const mergedOptions = [...enumOptions, ...additionalOptions].filter(
        (option, index, self) => index === self.findIndex((o) => o.value === option.value)
      );

      setPositionOptions(mergedOptions);
    }
  }, [contactPerson, isPositionEnumLoading, positionEnum?.contact_person_positions]);

  if (!isOpen) {
    return null;
  }

  return (
    <Form<FormData>
      onSubmit={async (values) => {
        const data = {
          ...values,
          manager: {
            ...values.manager,
            manager_group: values.company_position && values.company_position_type === 'group',
            manager_single: values.company_position && values.company_position_type === 'single',
          },
        };
        modifyContactPerson(data).then(() => {
          onClose();
        });
      }}
      data={inputData}
      schema={schema}
    >
      <Modal
        primaryAction={<SubmitButton label={t('Uložiť a zavrieť')} variant="raised" type="submit" />}
        title={t(`${subjectName} - ${contactPerson ? 'upraviť' : 'pridať'} kontaktnú osobu`)}
        icon={<LiaUserTieSolid className="size-8 text-primary-500" />}
        onClose={onClose}
      >
        <FormWrapper>
          <FieldSet title={t('Údaje kontaktnej osoby')} cols={6}>
            <div className="sm:col-span-2">
              <Field labelClassName="md:min-w-16" component={Input} hasInlineLabel name="name" />
            </div>
            <div className="sm:col-span-3">
              <Field labelClassName="md:min-w-16" component={Input} hasInlineLabel name="surname" />
            </div>
            <div className="sm:col-span-1">
              <Field labelClassName="md:min-w-8" component={Input} hasInlineLabel name="title" />
            </div>
            <div className="sm:col-span-2">
              <Field labelClassName="md:min-w-16" component={Input} hasInlineLabel name="mobile" type="phone" />
            </div>
            <div className="sm:col-span-2">
              <Field labelClassName="md:min-w-16" component={Input} hasInlineLabel name="email" type="email" />
            </div>
            <div className="sm:col-span-2">
              <Field
                isDisabled={isPositionEnumLoading}
                labelClassName="md:min-w-16"
                options={positionOptions}
                truncateWidth="max-w-40"
                component={Select}
                name="position"
                hasInlineLabel
                hasInsert
                isSearch
              />
            </div>
          </FieldSet>
          <FieldSet title={t('Konanie v mene spoločnosti')}>
            <div className="flex items-center">
              <div className="md:min-w-52">
                <Field labelClassName="lg:min-w-20" name="company_position" component={Switch} variant="left" />
              </div>
              <FormRender<FormData> condition={(s: FormState<FormData>) => s.values.company_position}>
                <Field
                  options={[
                    { label: t('Samostatne'), value: 'single' },
                    { label: t('Spoločne'), value: 'group' },
                  ]}
                  name="company_position_type"
                  component={RadioGroup}
                  hasInlineLabel
                />
              </FormRender>
            </div>
            <Field name="manager.signature_right" labelClassName="lg:min-w-20" component={Switch} variant="left" />
            <Field name="manager.state_in_documents" labelClassName="lg:min-w-20" component={Switch} variant="left" />
          </FieldSet>
          <FieldSet title={t('Poznámka ku kontaktnej osobe')}>
            <Field labelClassName="md:min-w-16" component={Input} hasInlineLabel name="note" />
          </FieldSet>
        </FormWrapper>
      </Modal>
    </Form>
  );
}
