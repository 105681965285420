import { useContext, createContext } from 'react';

import { CompanyModel } from 'app/api/requests';

const CompanyContext = createContext<CompanyModel | null>(null);

export default CompanyContext;

// eslint-disable-next-line react-refresh/only-export-components
export function useCompanyDetail() {
  return useContext(CompanyContext)!;
}
