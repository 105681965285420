import clsx from 'clsx';
import React from 'react';

type BadgeProps = {
  children: React.ReactNode;
  variant?: 'active' | 'blocked' | 'former' | 'lead' | 'not' | 'opportunity';
};

export default function Badge({ children, variant }: BadgeProps) {
  const getColorClass = (() => {
    switch (variant) {
      case 'active':
        return 'text-badge-active bg-badge-active/10';
      case 'blocked':
        return 'text-badge-blocked bg-badge-blocked/10';
      case 'former':
        return 'text-badge-former bg-badge-former/10';
      case 'lead':
        return 'text-badge-lead bg-badge-lead/10';
      case 'not':
        return 'text-badge-not bg-badge-not/10';
      case 'opportunity':
        return 'text-badge-opportunity bg-badge-opportunity/10';
      default:
        return 'text-neutral-800 bg-neutral-800/10';
    }
  })();

  return (
    <span
      className={clsx(`whitespace-nowrap rounded-badge px-2 py-1 text-xs font-bold leading-badge ${getColorClass}`)}
    >
      {children}
    </span>
  );
}
