import clsx from 'clsx';

import Label from '../Label';

interface Props {
  name: string;
  label: string;
  isBold?: boolean;
  errorText?: string;
  isDisabled?: boolean;
  value: boolean | null;
  labelClassName?: string;
  inputClassName?: string;
  hasInlineLabel?: boolean;
  variant?: 'left' | 'right';
  setValue: (checked: boolean) => void;
}

export default function Switch({
  errorText,
  inputClassName,
  isBold = false,
  isDisabled = false,
  label,
  labelClassName,
  name,
  setValue,
  value,
  variant = 'right',
}: Props) {
  const switchElement = (
    <div className="relative items-center">
      <input
        onChange={(e) => {
          setValue(e.target.checked);
        }}
        disabled={isDisabled}
        id={`switch-${name}`}
        className="hidden"
        checked={!!value}
        type="checkbox"
        name={name}
      />
      <button
        className={clsx(
          'relative block h-6 w-10 rounded-full transition-colors duration-300 ease-in-out',
          {
            'bg-neutral-200': !value,
            'bg-primary-500': value,
            'cursor-pointer': !isDisabled,
            'opacity-50 cursor-not-allowed': isDisabled,
          },
          inputClassName
        )}
        onClick={() => {
          if (!isDisabled) {
            setValue(!value);
          }
        }}
        type="button"
      >
        <div
          className={clsx('size-4 rounded-full bg-white shadow transition-transform duration-300 ease-in-out', {
            'border-red-500': errorText,
            'translate-x-1': !value,
            'translate-x-5': value,
          })}
        />
      </button>
      {errorText && <span className="text-error">{errorText}</span>}
    </div>
  );

  return (
    <div className="mt-2 flex flex-row justify-start gap-2">
      {variant === 'left' && switchElement}
      <Label
        className={clsx(
          {
            'cursor-pointer': !isDisabled,
            'ml-2': variant === 'left',
            'mr-2': variant === 'right',
            'opacity-50 cursor-not-allowed': isDisabled,
          },
          labelClassName
        )}
        name={`switch-${name}`}
        isBold={isBold}
        hasInlineLabel
        label={label}
      />
      {variant === 'right' && switchElement}
    </div>
  );
}
