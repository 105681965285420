import clsx from 'clsx';
import { ReactNode } from 'react';

import Label from 'core/components/Label';
import useSchemaMaxLen from 'core/containers/Form/useSchemaMaxLen';

interface Props {
  name: string;
  isBold?: boolean;
  label?: ReactNode;
  maxLength?: number; // https://www.w3schools.com/tags/att_textarea_maxlength.asp
  isChanged: boolean;
  errorText?: string;
  isRequired: boolean;
  isDisabled: boolean;
  isTouched?: boolean;
  value: null | string;
  labelClassName?: string;
  hasInlineLabel?: boolean;
  setValue: (v: null | string) => void;
}

export default function Textarea({
  errorText,
  hasInlineLabel = false,
  isBold = false,
  isDisabled = false,
  isRequired = false,
  label,
  labelClassName,
  maxLength,
  name,
  setValue,
  value,
}: Props) {
  const schemaMaxLen = useSchemaMaxLen(name);

  const maxLen = typeof maxLength === 'number' ? maxLength : schemaMaxLen;

  return (
    <div className="flex w-full flex-col gap-2">
      <div
        className={clsx({
          'flex-col': !hasInlineLabel,
          'flex-row': hasInlineLabel,
          'flex w-full justify-start gap-2': label,
        })}
      >
        {label && (
          <Label
            className={clsx('mt-2', labelClassName)}
            hasInlineLabel={hasInlineLabel}
            isRequired={isRequired}
            isBold={isBold}
            label={label}
            name={name}
          />
        )}

        <div className="w-full grow">
          <textarea
            className={clsx(
              'w-full rounded-md border bg-neutral-200 px-3 py-2 text-neutral-700 focus:outline-none focus:ring-2 focus:ring-primary-500',
              {
                'bg-neutral-200 opacity-50 cursor-not-allowed': isDisabled,

                'border-red-500': errorText,
              }
            )}
            onChange={(e) => {
              const inputValue = e.currentTarget.value;
              if (!maxLen || (value ?? '').length < maxLen || (value && inputValue.length < value.length)) {
                setValue(inputValue || null);
              }
            }}
            disabled={isDisabled}
            value={value ?? ''}
            name={name}
            rows={4}
          />

          {(!!errorText || !!maxLen) && (
            <div className="mt-1 flex flex-row">
              {errorText && <span className="text-error text-xs">{errorText}</span>}

              {maxLen && (
                <span className="ml-auto text-xs text-neutral-500">
                  ({(value ?? '').length}/{maxLen})
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
