import { useCallback } from 'react';
import * as Sentry from '@sentry/react';

import { UserModel } from 'app/api/requests';

/**
 * Request user profile
 */
export default function useGetProfile() {
  return useCallback((accessToken?: string): Promise<undefined | UserModel> => {
    return accessToken
      ? fetch(`${import.meta.env.VITE_APP_API_PATH}/auth/me`, {
          headers: { Accept: 'application/json', Authorization: `Bearer ${accessToken}` },
          method: 'POST',
        })
          .then((r) => (r.ok ? (r.json() as Promise<undefined | UserModel>) : Promise.resolve(undefined)))
          .then((u) => {
            Sentry.setUser({ email: u?.email, id: u?.id });
            return u;
          })
          .then(
            (u) =>
              u &&
              fetch(`${import.meta.env.VITE_APP_API_PATH}/users/${u.id}`, {
                headers: { Authorization: `Bearer ${accessToken}` },
              }).then((r) => (r.ok ? r.json() : undefined)?.then((r) => ({ ...(r?.data || {}), ...u })))
          )
      : Promise.resolve(undefined);
  }, []);
}
