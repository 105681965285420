import clsx from 'clsx';

import t from 'core/helpers/t';

import { ColDef } from './TableItem';

interface Props<D> {
  hasActions: boolean;
  columns: ColDef<D>[];
}

function TableHeader<D>({ columns, hasActions }: Props<D>) {
  return (
    <thead className="bg-white">
      <tr>
        {columns.map((c) => (
          <th
            className={clsx('px-4 py-[0.7rem] text-left', c.headerClassName)}
            key={'key' in c ? c.key : c.field.toString()}
          >
            <div dangerouslySetInnerHTML={{ __html: c.header }}></div>
          </th>
        ))}
        {hasActions && (
          <th className="px-4 py-[0.7rem] text-center" key="actions">
            {t('Akcia')}
          </th>
        )}
      </tr>
    </thead>
  );
}

export default TableHeader;
