import { matchPath } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function getBreadcrumbs(routes: any[], pathname: string) {
  const breadcrumbRoutes = getBreadcrumbRoutes(routes, pathname);

  let p = '/';

  return breadcrumbRoutes.map((r) => {
    if (r.prevBreadcrumb) {
      // manually added breadcrumb is not parent
      p = '/';
    }
    const a = p + r.path.replace('/*', '') + '/*';

    const m = matchPath(a, pathname);

    const i = { label: r.label, path: m?.pathnameBase || pathname };
    p = a.replace('/*', '/');

    return i;
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getBreadcrumbRoutes(routes: any[], pathname: string, prefix?: string): any[] {
  const route = routes.find((r) => {
    const xx = matchPath((prefix || '').replace('*', '') + r.path, pathname);

    return !!xx;
  });

  if (!route) {
    return [];
  }

  const prev = route.prevBreadcrumb ? routes.find((x) => x.path === route.prevBreadcrumb) : undefined;

  return (
    route.routes
      ? [prev, route, ...getBreadcrumbRoutes(route.routes, pathname, (prefix || '') + route.path)]
      : [prev, route]
  ).filter(Boolean);
}
